import Router from 'vue-router';
import Init from "@/views/Init";
// import Login from "@/views/Login";
import Price from "@/views/Price";
import Wallet from "@/views/Wallet";
import Audit from "@/views/Audit";
import Green from "@/views/Green";
import Recommend from "@/views/Recommend";
import rp from "@/views/51rp";
import hhj from "@/views/hhj";
import fx from "@/views/fangxin";
import Success from "@/views/Success";
import ApplyS from "@/views/ApplyS";
const routes = [
    // {
    //     path: '/Login',
    //     component: Login,
    //     name: 'Login',
    // },
    {
        path: '/ApplyS',
        component: ApplyS,
        name: 'ApplyS',
        meta: {
            title: '成功页面'
        }
    },
    {
        path: '/Init',
        component: Init,
        name: 'Init',
        meta: {
            title: '填写资料'
        }
    },
    {
        path: '/Green',
        component: Green,
        name: 'Green',
        meta: {
            title: '注册页'
        }
    },
    {
        path: '/51rp',
        component: rp,
        name: 'rp',
        meta: {
            title: '注册页'
        }
    },
    {
        path: '/hhj',
        component: hhj,
        name: 'hhj',
        meta: {
            title: '注册页'
        }
    },
    {
        path: '/fx',
        component: fx,
        name: 'fx',
        meta: {
            title: '注册页'
        }
    },
    {
        path: '/Price',
        component: Price,
        name: 'Price',
        meta: {
            title: '放薪'
        }
    },
    // {
    //     path: '/',
    //     component: Price,
    //     name: 'Price',
    //     meta: {
    //         title: '放薪'
    //     }
    // },
    {
        path: '/Wallet',
        component: Wallet,
        name: 'Wallet',
        meta: {
            title: '申请页'
        }
    },
    {
        path: '/Audit',
        component: Audit,
        name: 'Audit',
        meta: {
            title: '下载'
        }
    },
    {
        path: '/Recommend',
        component: Recommend,
        name: 'Recommend',
        meta: {
            title: '产品页'
        }
    },
    {
        path: '/Success',
        component: Success,
        name: '/Success',
        meta: {
            title: '匹配成功'
        }
    },

];

const router = new Router({
    mode: 'history',
    routes: routes
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next()
  })
export default router;
