<template>
    <div class="hhj" v-if="Pagestatus">
        <div class="versionOneBox">
            <div class="top_head">
                <!-- <img src="https://static.fangxin.plus/h5/old_h5/img/hhj-logo.png" alt=""> -->
                <p data-v-4388ac03="" class="text1">可借金额高至(元)</p>
                <span data-v-4388ac03="" class="qian">
                    25,000
                </span>
                <div data-v-4388ac03="" class="limit">
                    <div data-v-4388ac03="">息费透明</div>
                    <p data-v-4388ac03="">
                        年化利率7.2%起，借1千元，用1天仅需0.2元起
                    </p>
                </div>





            </div>
            <div class="box">

                <div class="inp_box">
                    <p data-v-4388ac03=""><span data-v-4388ac03="" class="label">借款期限</span>
                        <span data-v-4388ac03="" class="value">6、12、24期</span>
                    </p>


                    <p style="margin-top:0.27rem" data-v-4388ac03=""><span data-v-4388ac03="" class="label">还款规则</span>
                        <span data-v-4388ac03="" class="value">当日借款、次日可还、按日计息</span>
                    </p>


                    <div data-v-4388ac03="" class="inp_cpl">
                        <div  data-v-4388ac03="" class="versionFormOneIn">
                            <div  class="frombox">
                                <div  class="input">
                                    <img  style="width:21px" src="https://static.fangxin.plus/h5/old_h5/img/hhj-phone.png" alt="">



                                    <input v-model="phone" type="tel" maxlength="11" placeholder="请输入手机号码"
                                        class="tel">


                                </div>
                                <!---->
                                <div  @click="next"  class="btnbox"><i >立即申请</i></div>
                                <div  class="xieyi">
                                    <div @click = "changeFlag" v-if="!this.checked" ><img style="width:15px" 
                                            src="https://static.fangxin.plus/h5/old_h5/img/hhj-10.png" alt="">
                                        <!---->
                                    </div>
                                    <div @click = "changeFlag" v-else ><img style="width:15px" 
                                            src="https://static.fangxin.plus/h5/old_h5/img/hhj-10-1.png" alt="">
                                        <!---->
                                    </div>
                                    <p  class="p1">我已同意并阅读</p>
                                    <p @click="showService" class="p2">
                                        《用户服务协议》
                                    </p>
                                    <p @click="showBook" class="p2">《隐私政策》</p>
                                </div>
                            </div>
                            <!---->
                        </div>
                    </div>









                </div>

                <!-- <div data-v-4388ac03="" class="banner">
                    <p data-v-4388ac03="">新人专享礼</p> <img data-v-4388ac03="" alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-vip.png">
                </div> -->



                <div data-v-4388ac03="" class="step">
                    <div data-v-4388ac03="" class="title"><img data-v-4388ac03="" alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-l.png">
                        <p data-v-4388ac03="">3步轻松借款</p> <img data-v-4388ac03="" alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-r.png">
                    </div>
                    <div data-v-4388ac03="" class="steps">
                        <div data-v-4388ac03="" class="item"><img style="width:28px;height: 32px;" data-v-4388ac03=""
                                alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-1.png">
                            <p data-v-4388ac03="" class="p1">填写资料</p>
                            <p data-v-4388ac03="" class="p2">个人资料认证</p>
                        </div>
                        <div data-v-4388ac03="" class="sp"><img style="width:25px;height: 17px;" data-v-4388ac03=""
                                alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-4.png">
                        </div>
                        <div data-v-4388ac03="" class="item"><img style="width:28px;height: 32px;" data-v-4388ac03=""
                                alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-2.png">
                            <p data-v-4388ac03="" class="p1">精准匹配</p>
                            <p data-v-4388ac03="" class="p2">100+产品智能筛选</p>
                        </div>
                        <div data-v-4388ac03="" class="sp"><img style="width:25px;height: 17px;" data-v-4388ac03=""
                                alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-4.png">
                        </div>
                        <div data-v-4388ac03="" class="item"><img style="width:28px;height: 32px;" data-v-4388ac03=""
                                alt="" src="https://static.fangxin.plus/h5/old_h5/img/hhj-3.png">
                            <p data-v-4388ac03="" class="p1">快速提现</p>
                            <p data-v-4388ac03="" class="p2">24小时随时借款</p>
                        </div>
                    </div>
                </div>


                <div class="fotban">

                    <div data-v-4388ac03="" class="div">
                        <div style="font-size: 0.26666667rem; text-align:center; color:#8f919c;line-height: 0.45rem;">
                            本平台是金融机构第三方互联网营销平台 </div>
                        <div style="font-size: 0.26666667rem; text-align:center; color:#8f919c;line-height: 0.45rem;">
                            为客户提供贷款推荐和咨询服务 </div>
                        <div style="font-size: 0.26666667rem; text-align:center; color:#8f919c;line-height: 0.45rem;">
                            放款由第三方金融机构提供 </div>
                        <div style="font-size: 0.26666667rem; text-align:center; color:#8f919c;line-height: 0.45rem;">
                            请根据个人能力合理贷款，理性消费，避免逾期 </div>
                        <div style="font-size: 0.26666667rem; text-align:center; color:#8f919c;line-height: 0.45rem;">
                            所有贷款申请在未成功贷款前绝不收取任何费用，为了保证您的资金安全，请不要相信任何要求您支付费用的信息、邮件、电话等不实信息。 </div>
                      
                        <div style="font-size: 0.26666667rem; text-align:center; color:#8f919c;line-height: 0.45rem;">
                        </div>
                    </div>
                </div>


            </div>



        </div>


        <van-dialog @confirm="inputCode" v-model="showDialog" :before-close="onBeforeClose" title="请输入验证码"
            show-cancel-button>
            <div style="padding: 20px">
                <el-input placeholder="验证码输入" v-model="code" suffix="showDialog" type="text">
                    <a style="line-height: 40px; color:#1c6ffd" slot="suffix" @click="sendCode">
                        {{ count || "获取验证码" }}
                    </a>
                </el-input>
            </div>
        </van-dialog>


        <div v-if="loading" style="position: fixed;top: 0;width: 100%; height: 100%; background: rgb(0 0 0 / 60%)">
            <div style="position: fixed; top: 45vh; align-items: center; width: 100%">
                <van-loading v-if="loading" />
            </div>
        </div>

    </div>










</template>

<script>
// eslint-disable-next-line no-unused-vars
import { Dialog, Field, Loading, Toast } from 'vant';

import { login, sendVerifyCode, sendVerifyCodeAndLogin,
    getChannelUrl 
} from "@/api/api";
import 'vant/lib/dialog/style';
import 'vant/lib/toast/style';
import 'vant/lib/overlay/style';
import 'vant/lib/field/style';
import Vue from 'vue';
// import axios from "axios";

Vue.use(Field);
Vue.use(Loading);


export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        [Dialog.Component.name]: Dialog.Component
    },

    name: "Price",

    data() {

        return {
            Pagestatus:false,
            loading: false,
            showDialog: false,
            s1: false,
            s2: false,
            s3: false,

            selList: [
                { month: 12, ret: 0, interest: 1 },
                { month: 6, ret: 0, interest: 1 },
                { month: 3, ret: 0, interest: 1 },
            ],
            selectIndex: 0,


            TIME_COUNT: 60,
            count: "",
            code: "",
            phone: "",
            price: 20000,

            checked: false,
            show: true,
        }
    },

    created() {
        window.sessionStorage.setItem("toCode", this.$route.query.code);
      
        getChannelUrl(this.$route.query.code)
        .then(res => {
            console.log(res.data);
            if(res.data.code === 200){
                if(res.data.data.redirect && res.data.data.redirect_url!= null){
                    window.location.href = res.data.data.redirect_url
                }else{
                    this.Pagestatus = true
                }
            }else{
                Toast(res.data.msg);
            }
        })
    
    },
    methods: {
        sendCode() {
            if (this.count) {
                this.$toast("请稍等...")
                return;
            }
            if (!this.timer) {
                this.count = this.TIME_COUNT
                this.show = false
                this.timer = setInterval(() => {
                    if (this.count > 1 && this.count <= this.TIME_COUNT) {
                        this.count--
                    } else {
                        this.count = "";
                        this.show = true
                        clearInterval(this.timer) // 清除定时器
                        this.timer = null
                    }
                }, 1000)
            } else {
                let data = {
                    mobile: this.phone,
                    verifyCode: this.code
                }
                this.showDialog = true;
                sendVerifyCode(data).then((response) => {
                    if (response.data.code === 200) {
                        window.sessionStorage.setItem("loanAmount", this.price)
                        window.sessionStorage.setItem("loanTime", 12)
                        window.sessionStorage.setItem("phone", this.phone)
                    } else {
                        Toast(response.data.msg);
                    }
                }).catch((e) => {
                    Toast("服务器异常:" + e)
                    this.showDialog = false;
                    return
                })
            }
        },
        showService() {
            Dialog.alert({
                messageAlign: 'left',
                height: '200px',
                confirmButtonColor: "#1c6ffd",
                title: '用户注册服务协议',
                allowHtml: true,
                closeOnClickOverlay: true,
                message: "<div style='height:200px;'>       卡卡（以下简称“平台”）由安徽卡卡信息技术有限公司运营，用户承诺在仔细阅读和完全接受《用户注册服务协议》（以下简称“本协议”）项下全部条款的基础上使用本平台的各项服务。用户接受本协议点击\"同意、同意并注册\"按钮或其他类似意思表示完成注册即表示完全接受以上述及的所有条款。\n" +
                    "        本平台可能根据法律法规的要求或业务运营的需要，对本协议服务条款不时进行修改。除非另有规定，否则任何修改将在该等修改内容发布之时立即生效，您对本平台的注册、使用、及继续使用均表明您接受此等修改。如果您不同意本协议服务条款（包括本平台可能不定时对其或其中引述的其他规则所进行的任何修改）的任一规定，则请勿注册或使用本平台，或您可以主动取消本平台提供的服务。\n" +
                    "        为了便于您了解适用于您的条款和条件，我们将在平台上发布对本协议服务条款的修改，您应不时地审阅本协议服务条款以及经参引而并入其中的其他规则。\n" +
                    "一、服务内容\n" +
                    "    1、本平台为信息技术服务平台，您可通过本平台发布自己的借款需求，经您的合法授权后，放款平台或信贷员可在本平台查看并获取您的借款信息和联系方式，并与您联系协商借贷事宜。\n" +
                    "    2、本平台仅为借贷需求双方提供交易机会与信息服务，不参与借款行为以及其他交易行为，不提供资金往来或托管服务。您应审慎对待自己的交易行为，您与出借人之间发生的借贷行为均与本平台无关，本平台不对借款双方以及第三方借贷渠道提供任何形式的担保。\n" +
                    "    3、平台的具体服务内容由我们根据实际情况提供并不时更新，包括但不限于信息、图片、文章、评论、链接等，我们将定期或不定期根据用户的意愿以电子邮件、短信、电话或站内信等方式为用户提供活动信息，并向用户提供相应服务，具体服务以平台实际展示为准。我们对提供的服务拥有最终解释权。\n" +
                    "    4、平台服务仅供个人用户使用。除我们书面同意，您或其他用户均不得将平台上的任何信息用于商业目的。\n" +
                    "    5、您使用平台服务时所需的相关的设备以及网络资源等（如个人电脑、手机及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均由您自行负担。\n" +
                    "二、信息提供和隐私保护\n" +
                    "    1、您在注册账号或使用本平台服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，用户需要填写真实的身份信息。若您填写的信息不完整，则无法使用本平台服务或在使用过程中受到限制。您有义务保证密码和帐号的安全，并保证账号及密码仅供自己使用。您同意不断更新注册资料，符合及时、详尽准确的要求，所有原始键入的资料将引用为注册资料。\n" +
                    "    2、 您在访问、使用平台或申请使用平台服务时，必须提供本人真实的个人信息，且您应该根据实际变动情况及时更新个人信息。保护用户隐私是我们的重点原则，我们通过各种技术手段和强化内部管理等办法提供隐私保护服务功能，充分保护您的个人信息安全。\n" +
                    "    3、平台不负责审核您提供的个人信息的真实性、准确性或完整性，因信息不真实、不准确或不完整而引起的任何问题及其后果，由您自行承担，且您应保证我们免受由此而产生的任何损害或责任。若我们发现您提供的个人信息是虚假、不准确或不完整的，我们有权自行决定终止向您提供服务。\n" +
                    "    4、您已明确授权，为提供服务、履行协议、解决争议、保障交易安全等目的，我们对您提供的、我们自行收集的及通过第三方收集的您的个人信息、您申请服务时的相关信息、您在使用服务时储存在平台的非公开内容以及您的其他个人资料（以下简称“个人资料”）享有留存、整理加工、使用和披露的权利，且您明确授权平台及其关联公司及其指定的第三方可留存、整理及加工本次获取的本人信息用于平台及其关联公司及其指定的第三方为您提供的包括但不限于营销等其他服务中，具体方式包括但不限于：\n" +
                    "    （1）出于为您提供服务的需要在本平台公示您的个人资料；\n" +
                    "    （2）由人工或自动程序对您的个人资料进行获取、评估、整理、存储；\n" +
                    "    （3）使用您的个人资料以改进本平台的设计和推广；\n" +
                    "    （4）使用您提供的联系方式与您联络并向您传递有关服务和管理方面的信息；\n" +
                    "    （5）对您的个人资料进行分析整合并向为您提供服务的第三方提供为完成该项服务必要的信息。当为您提供服务的第三方与您电话核实信息时，为保证为您服务的质量，你同意平台对上述核实电话进行录音。\n" +
                    "    （6）在您违反与我们或我们的其他用户签订的协议时，披露您的个人资料及违约事实，将您的违约信息写入黑名单并与必要的第三方共享数据，以供我们及第三方审核、追索之用。\n" +
                    "    （7）其他必要的使用及披露您个人资料的情形。\n" +
                    "为更好地为您提供服务，您同意并授权平台可与其合作的第三方进行联合研究，并可将通过本协议获得的您的信息投入到该等联合研究中。但平台与其合作的第三方在开展上述联合研究前，应要求其合作的第三方对在联合研究中所获取的您的信息予以保密。\n" +
                    "您在此授权我们及关联公司、合作伙伴可以通过短信或邮箱向您发送商业信息。\n" +
                    "为向您提供本平台信息技术服务之目的，本平台会将您的借款信息及联系方式展示给信贷员及放款机构，您在此授权本平台可进行上述操作。\n" +
                    "我们保证采用行业惯例以保护您的资料，但您理解，鉴于技术限制，我们无法确保用户的个人信息完全不被泄露。\n" +
                    "我们不会向与您无关的第三方恶意出售或免费提供您的个人资料，\n" +
                    "但下列情况除外： \n" +
                    "    （1）事先获得您的明确授权；\n" +
                    "    （2）按照相关司法机构或政府主管部门的要求；\n" +
                    "    （3）以维护我们合法权益之目的； \n" +
                    "    （4）维护社会公众利益； \n" +
                    "    （5）只有共享您的信息，才能提供您需要的服务和（或）产品。\n" +
                    "    （6）相应的法律、法规要求及程序服务需要平台提供用户的个人资料。\n" +
                    "本授权可在您接受平台（或其委托或合作的第三方）提供服务的过程中多次使用，本平台（或其委托或合作的第三方）及合作机构自您签署本授权之日起可多次依据本授权而操作执行本授权项下的活动无需您另行授权。\n" +
                    "您确认已充分被告知、了解并接受上述授权的法律后果。\n" +
                    "三、使用准则\n" +
                    "用户在申请使用平台提供的网络服务时，必须向平台提供准确的个人资料，如个人资料有任何变动，必须及时更新。若您填写的信息不完整或不准确，则无法使用本平台服务或在使用过程中受到限制。\n" +
                    "用户注册成功后，平台将给予您一个用户账号及相应的密码，该用户账号和密码由用户负责保管；用户应当对以其用户账号进行的所有言论、行为、活动和事件承担法律责任。\n" +
                    " 用户的账号名称应当符合《互联网用户账号名称管理规定》之规定，在账号名称、头像和简介等注册信息中不得出现违法和不良信息。平台对用户提交的账号名称、头像和简介等注册信息有权进行审核，对含有违法和不良信息的，不予注册。对于已经注册的含有违法或者不良信息的账号名称、头像、简介，平台有权采取通知限期改正、暂停使用、注销登记等措施，造成的后果由用户自行承担。对冒用、关联机构或社会名人注册账号名称的，平台有权注销其账号，并向互联网信息内容主管部门报告。\n" +
                    "平台对用户提交的账号名称等信息予以审核通过并不代表平台对其予以任何批准、许可、授权、同意或者支持，用户仍然应当自行承担其法律责任，且平台保留进行后续不时审核并予以处理的权利。\n" +
                    "用户在使用平台某些服务时，平台需要对用户身份进行特别验证，具体的验证方式以各服务模块页面显示为准。\n" +
                    "用户不得利用平台的服务从事下列危害互联网信息网络安全的活动：\n" +
                    "    (1) 未经允许，进入互联网信息网络或者使用互联网信息网络资源；\n" +
                    "    (2) 未经允许，对互联网信息网络功能进行删除、修改或者增加；\n" +
                    "    (3) 未经允许，对进入互联网信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；\n" +
                    "    (4) 故意制作、传播计算机或手机病毒等破坏性程序；\n" +
                    "    (5) 其他危害互联网信息网络安全的行为。 \n" +
                    "如您的操作影响系统总体稳定性或完整性，我们将暂停或终止您的操作，直到相关问题得到解决。\n" +
                    "四、免责声明\n" +
                    "    1、平台是一个开放平台，用户将文章或照片等个人资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。作为网络服务的提供者，我们对用户在任何论坛、个人主页或其它互动区域提供的任何陈述、声明或内容均不承担责任。您明确同意使用平台服务所存在的风险或产生的一切后果将完全由您自身承担，我们对上述风险或后果不承担任何责任。\n" +
                    "    2、 您违反本注册协议、违反道德或法律的，侵犯他人权利（包括但不限于知识产权）的，我们不承担任何责任。同时，我们对任何第三方通过平台发送服务或包含在服务中的任何内容不承担责任。\n" +
                    "    3、 对您、其他用户或任何第三方发布、存储或上传的任何内容或由该等内容导致的任何损失或损害，我们不承担责任。 \n" +
                    "    4、对任何第三方通过平台可能对您造成的任何错误、中伤、诽谤、诬蔑、不作为、谬误、淫秽、色情或亵渎，我们不承担责任。 \n" +
                    "    5、 对黑客行为、计算机或手机病毒、或因您保管疏忽致使帐号、密码被他人非法使用、盗用、篡改的或丢失，或由于与本APP站链接的其它网站或APP所造成您个人资料的泄露，或您因其他非平台原因造成的损失，我们不承担责任。如您发现任何非法使用用户帐号或安全漏洞的情况，请立即与我们联系。\n" +
                    "    6、 因任何非平台原因造成的网络服务中断或其他缺陷，我们不承担任何责任。\n" +
                    "    7、 我们不保证服务一定能满足您的要求；不保证服务不会中断，也不保证服务的及时性、安全性、准确性。\n" +
                    "    8、平台不以提供服务方的身份参与任何借贷行为，平台并不实质性介入您与第三方之间的交易，对此，您充分理解并认可。平台不对任何借贷行为进行背书或担保。平台提供的服务中不带有对平台中的任何用户、任何交易的任何保证或条件，无论是明示、默示或法定的，因此平台及其股东、创建人、高级职员、董事、代理人、关联公司、母公司、子公司和雇员等均不能完全保证网站内容的真实性、充分性、及时性、可靠性、完整性和有效性，并且免除任何由此引起的法律责任。\n" +
                    "五、服务变更、中断或终止\n" +
                    "    1、 如因升级的需要而需暂停服务、或调整服务内容，我们将尽可能在平台上进行通告。由于用户未能及时浏览通告而造成的损失，我们不承担任何责任。 \n" +
                    "    2、您明确同意，我们保留根据实际情况随时调整平台提供的服务内容、种类和形式，或自行决定授权第三方向您提供原本我们提供的服务。因业务调整给您或其他用户造成的损失，我们不承担任何责任。同时，我们保留随时变更、中断或终止平台全部或部分服务的权利。 \n" +
                    "    3、 发生下列任何一种情形，我们有权单方面中断或终止向您提供服务而无需通知您，且无需对您或第三方承担任何责任：\n" +
                    "    (1) 您提供的个人资料不真实； \n" +
                    "    (2) 您违反本注册协议；\n" +
                    "    (3) 未经我们书面同意，将平台平台用于商业目的。 \n" +
                    "    4、您可随时通知我们终止向您提供服务或直接取消平台服务。自您终止或取消服务之日起，我们不再向您承担任何形式的责任。\n" +
                    "六、知识产权及其它权利\n" +
                    "    1、对您在平台发布或以其它方式传播的内容，您作如下声明和保证：\n" +
                    "    (1)对于该等内容，您具有所有权或使用权； \n" +
                    "    (2)该等内容是合法的、真实的、准确的、非误导性的； \n" +
                    "    (3)使用和发布此等内容或以其它方式传播此等内容不违反本注册协议，也不侵犯任何人或实体的任何权利或造成对任何人或实体的伤害。 \n" +
                    "    3、未经相关内容权利人的事先书面同意，您不得擅自复制、传播在平台的该等内容，或将其用于任何商业目的，所有这些资料或资料的任何部分仅可作为个人或非商业用途而保存在某台计算机或其他电子设备内。否则，我们及/或权利人将追究您的法律责任。 \n" +
                    "    4、您在平台发布或传播的自有内容或具有使用权的内容，您特此同意如下： \n" +
                    "    (1)授予我们使用、复制、修改、改编、翻译、传播、发表此等内容，从此等内容创建派生作品，以及在全世界范围内通过任何媒介（现在已知的或今后发明的）公开展示和表演此等内容的权利； \n" +
                    "    (2)授予我们及其关联方和再许可人一项权利，可依他们的选择而使用用户有关此等内容而提交的名称；\n" +
                    "    (3)授予我们在第三方侵犯您在平台的权益、或您发布在平台的内容情况下，依法追究其责任的权利（但这并非我们的义务）；\n" +
                    "七、特别约定\n" +
                    "    1、您使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方的合法权益的情形时，我们有权直接删除该等违反规定之信息，并可以暂停或终止向您提供服务。 \n" +
                    "    2、若您利用平台服务从事任何违法或侵权行为，由您自行承担全部责任，因此给我们或任何第三方造成任何损失，您应负责全额赔偿，并使我们免受由此产生的任何损害。 \n" +
                    "    3、 您同意我们通过重要页面的公告、通告、电子邮件以及常规信件的形式向您传送与平台服务有关的任何通知和通告。同时，平台保留对用来申请平台用户注册的电子邮箱投放商业性广告的权利。\n" +
                    "    4、 本注册协议之效力、解释、执行均适用中华人民共和国法律。 \n" +
                    "    5、若非平台更新本协议，您再确认同意、签署本协议后，其效力将及于您此时及未来登陆平台时所有操作。\n" +
                    "    6、您在本协议项下对本公司的授权将视为对本公司及本公司之关联公司的授权。本公司及本公司关联公司均可凭借您的授权及本协议约定执行相关操作。 \n" +
                    "    7、 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向本平台所在地人民法院提起诉讼。 \n" +
                    "    8、本注册协议中的标题仅为方便而设，不影响对于条款本身的解释。本注册协议最终解释权归平台所有。\n </div>",
                theme: 'round-button',
            }).then(() => {
                // on close
            });
            this.checked = false
        },

        showBook() {
            Dialog.alert({
                confirmButtonColor: "#1c6ffd",
                title: '隐私协议',
                messageAlign: 'left',
                allowHtml: true,
                closeOnClickOverlay: true,
                message: "<div style='height:200px;'>        安徽卡卡信息技术有限公司及其关联方（简称“我们”）作为本产品的运营者，深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息，同时我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定本“隐私政策”并特别提示：希望您在使用本产品及相关服务前仔细阅读并理解本《隐私政策》，以便做出适当的选择。\n" +
                    '    您的个人信息安全对我们来说至关重要。我们将依据《中华人民共和国网络安全法》、《信息安全技术 个人信息安全规范》（GB/T 35273-2017）以及其他相关法律法规和技术规范收集和使用您的个人信息，以帮助我们向您提供更优质的产品和/或服务。我们承诺会对您的个人信息和其它数据进行严格保密，并严格按照本隐私政策所阐述的内容处理您的个人信息。我们会根据您的同意和其它可处理您个人信息的法律依据收集、使用、存储、共享和转移您的个人信息。\n' +
                    '以下是本隐私政策的要点说明：\n' +
                    '    为帮助您注册并使用本产品或服务，我们可能收集与提供服务相关的个人信息，您有权拒绝或撤回授权；\n' +
                    '    您可以访问、更正、删除您的个人信息，改变您授权同意的范围，注销您的帐号，我们为您提供了行使这些权利的途径。\n' +
                    '    我们采取了互联网业内标准的技术措施和数据安全措施来保护您的个人信息安全。\n' +
                    '    除非再次征得您的同意，我们不会将您的个人信息用于本隐私政策未载明的其他目的。\n' +
                    '    当您对本隐私政策有任何疑问，可以和我们进一步联系与咨询。\n' +
                    '目录\n' +
                    '    引言；\n' +
                    '        我们处理个人信息的法律依据；\n' +
                    '        我们如何收集您的个人信息；\n' +
                    '        我们如何使用您的个人信息；\n' +
                    '        我们如何共享、转让、公开披露您的个人信息；\n' +
                    '        您如何访问和控制自己的信息；\n' +
                    '        自动索引技术的使用；\n' +
                    '        您的个人信息保护；\n' +
                    '        本隐私政策的修订；\n' +
                    '        其他；\n' +
                    '    我们尊重并保护您的隐私，在您使用我们的服务时，为便于我们及时了解并满足您的需求，我们可能会收集和使用您的相关信息。我们希望能够通过本《隐私政策》向您充分解释和说明，在您使用我们的服务时，我们将如何收集、使用、储存和分享您的这些个人信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。《隐私政策》与您使用的平台服务息息相关，我们希望您能够仔细阅读，在需要时，按照《隐私政策》的指引，做出您认为适当的选择。\n' +
                    '    如果您使用或继续使用我们的服务，即意味着同意我们根据《隐私政策》约定收集、使用、储存和分享您的相关信息。如您不同意《隐私政策》中的内容，则应当立即停止使用我们的服务。\n' +
                    '    我们将依据《中华人民共和国网络安全法》、《信息安全技术 个人信息安全规范》（GB/T 35273-2017）以及其他相关法律法规收集和使用您的个人信息，为您提供产品和/或服务。我们通常只会在征得您同意的情况下收集您的个人信息。在某些情况下，我们可能还会基于法律义务或者履行合同之必需向您收集个人信息，或者可能需要个人信息来保护您的重要利益或其他人的利益。\n' +
                    '    我们会遵循正当、合法、必要的原则，出于本隐私政策所述的以下目的，收集和使用您在使用服务过程中主动提供或因使用本平台产品和/或服务而产生的个人信息。如果您不提供相关信息，可能无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。如您继续使用我们的服务，则视为您同意我们通过以下方式获得与您有关的信息并进行存储。\n' +
                    '3.1 您向我们提供的信息\n' +
                    '    1）注册/登录本平台时提供的手机号码；\n' +
                    '    2）使用本平台服务或参与平台活动时可能要求提供的身份信息，包括但不限于您的姓名、性别、住址、电子邮箱、电话号码、身份证号、工作信息、学历、婚姻状况、紧急联系人、设备信息、生物特征、运营商账号、密码、手机账单及通讯录等信息；\n' +
                    '    3）我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。\n' +
                    '    4）您向我们提供上述信息后，我们即有权存储该信息并有权根据《隐私政策》约定使用和分享该信息。\n' +
                    '3.2 我们在您使用服务时获取的信息\n' +
                    '    1） 当您使用我们的服务时，我们可能会自动收集相关信息并存储为服务日志信息。我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括但不限于SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。\n' +
                    '    2） 为了帮助您更好地使用我们的产品或服务，我们会收集服务过程信息。例如，您使用我们的产品推荐服务时，我们将可能收集您的点击和申请记录。\n' +
                    '    3） 为提高您使用本平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。\n' +
                    '3.3 我们从第三方处获得的信息\n' +
                    '    1） 您同意并于此授权我们查看、储存您的通讯运营商基础资料，包括但不限于您的登记姓名、手机号码、归属省份、话费余额、套餐信息（部分）、账单信息、开户日期（部分）、本地通讯录，包括姓名、电话号码、公司（如有）、电子邮件（如有）、地址（如有）、个人资料（如有）、备注（如有）。\n' +
                    '    2） 您同意并于此授权我们查看、储存通讯运营商其他相关资料，包括但不限于通话记录相关信息（包括但不限于通话发生时间、通话发生地、主叫/被叫、通话对方号码、通话时长（秒）、本地漫游长途属性）、短信记录相关信息（包括但不限于服务类型、短信发送/接受时间、对方号码）等。\n' +
                    '    3） 您同意并于此授权我们可以根据您提供的姓名、身份证号码、银行卡号、实名手机号等信息向依法设立的第三方征信机构、大数据平台、银行、财务机构、第三方风控平台、互联网金融风险信息共享平台查询您的相关信用信息，包括但不限于您的信用分、信用报告等。\n' +
                    '    4） 您同意并于此授权平台及与平台合作的第三方查看社保信息包括但不限于账号信息、公积金缴存情况等信息。\n' +
                    '3.4征得授权同意的例外\n' +
                    '根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：\n' +
                    '    1） 与国家安全、国防安全直接相关的；\n' +
                    '    2） 与公共安全、公共卫生、重大公共利益直接相关的；\n' +
                    '    3）与犯罪侦查、起诉、审判和判决执行等直接相关的；\n' +
                    '    4） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n' +
                    '    5） 所收集的个人信息是您自行向社会公众公开的；\n' +
                    '    6） 从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；\n' +
                    '    7） 根据您的要求签订和履行合同所必需的；\n' +
                    '    8） 用于维护本平台的产品和/或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；\n' +
                    '    9） 法律法规规定的其他情形。\n' +
                    '您知悉并同意，我们有权将在向您提供服务的过程之中所收集的信息作下列用途：\n' +
                    '4.1 向你提供您使用的各项服务，并维护、改进这些服务；\n' +
                    '4.2 在我们提供服务时用于身份验证、客户服务、安全防范、存档和备份用途，确保我们向您提供的产品和服务的安全性；\n' +
                    '4.3 评估、改善我们的广告投放和其他促销及推广活动的效果；\n' +
                    '4.4 邀请您参与有关我们服务的调查；\n' +
                    '4.5 经您许可的其他用途；\n' +
                    '4.6 具体使用方式包括但不限于：\n' +
                    '    1） 为了能够为您提供个性化的服务，在您安装本平台软件时，我们需要您授权我们读取您的地理位置信息；\n' +
                    '    2） 为了保证我们的服务质量，在您填写手机运营服务商及密码时，我们需要您授权我们使用您的电话号码及密码去依法设立的第三方查询机构查询并存储您近期的话费账单等信息；\n' +
                    '    3） 为了向您提供更好的服务，在您享受我们为您提供的资信查询服务填写身份信息时，我们需要您授权我们使用您的姓名、身份证号码、手机号等个人信息去依法设立的第三方查询机构查询并存储您的行为记录等信息。\n' +
                    '5.1 共享\n' +
                    '我们对您的信息承担保密义务。除以下情形外，未经您的同意，我们及我们的关联公司不会与任何第三方分享您的个人信息：\n' +
                    '    1） 在获得您的明确同意后，我们会与其他方共享您的个人信息。\n' +
                    '    2） 根据有关法律法规要求向有权的仲裁机构或司法、行政、立法等权力机关提供您的个人资料和信息。\n' +
                    '    3） 提供给我们的关联方、合作方（包括但不限于合作机构，下同）以用于完成我们对您提供的服务（包括但不限于对您的个人资料和信息进行核实、对您的信用情况进行评估等），我们只会共享完成服务所必要的个人信息，且受本隐私政策中所声明目的的约束。\n' +
                    '    4）我们及关联公司及相关合作伙伴、第三方服务商可能定期或不定期向您发送有关产品、服务或相关活动的信息，您同意接收上述信息。\n' +
                    '    5） 提供给您通过我们提供的服务达成交易的交易方。\n' +
                    '    6） 其他经您许可的情形。\n' +
                    '5.2 转让\n' +
                    '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：\n' +
                    '    1）在获得您的明确同意后，我们会向其他方转让您的个人信息；\n' +
                    '    2）在本产品服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。\n' +
                    '5.3 公开披露\n' +
                    '我们可能基于以下目的披露您的个人信息：\n' +
                    '    1） 遵守适用的法律法规等有关规定；\n' +
                    '    2） 遵守法院判决、裁定或其他法律程序的规定；\n' +
                    '    3） 遵守相关政府机关或其他法定授权组织的要求；\n' +
                    '    4） 我们有理由确信需要遵守法律法规等有关规定；\n' +
                    '    5） 为执行相关服务隐私政策或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。\n' +
                    '5.4 共享、转让、公开披露您个人信息无需事先征得您的授权同意：\n' +
                    '    1） 与国家安全、国防安全有关的；\n' +
                    '    2） 与公共安全、公共卫生、重大公共利益有关的；\n' +
                    '    3） 与犯罪侦查、起诉、审判和判决执行等有关的；\n' +
                    '    4） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n' +
                    '    5） 您自行向社会公众公开的个人信息；\n' +
                    '    6） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。\n' +
                    '5.5 根据法律规定，分享、转让已经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外分享、转让及公开行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。\n' +
                    '6.1 您可以在使用我们服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息，也可按照通知指引与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。例如，若您在使用地理位置相关服务时，希望停止分享您的地理位置信息，您可通过手机定位关闭功能、软硬件服务商及通讯服务提供商的关闭方式停止分享，建议您仔细阅读相关指引。\n' +
                    '6.2 当您发现我们除了关于您的个人信息有错误时，您有权要求我们做出更正或者补充。您可以通过本隐私政策中提及的方式提出更正或补充申请。\n' +
                    '6.3 您可以通过本隐私政策中提及的方式删除您的部分个人信息。在以下情形中，您可以向我们申请删除您的个人信息：\n' +
                    '    1） 如果我们处理个人信息的行为违反了法律法规；\n' +
                    '    2） 如果我们在未征得您明确的同意下收集、使用您的个人信息；\n' +
                    '    3） 如果我们处理个人信息的行为严重违反了与您的约定；\n' +
                    '    4） 如果您不再使用我们的产品或服务，或您主动注销了账号；\n' +
                    '    5） 如果我们永久不再为您提供产品或服务。\n' +
                    '6.4 在您访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障帐号的安全。\n' +
                    '6.5 您可以通过联系我们的客服提交注销账户的申请，我们将会在*个工作日内为您销户。在您注销账户后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息或使其匿名化处理。\n' +
                    '6.6 请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。\n' +
                    '    为了更好的提供服务，我们会使用自动索引和类似的技术来提供我们的服务，并协助收集数据。您同意我们使用这类技术获得您浏览第三方平台所产生的个人数据信息，我们将使用这些信息来分析您的偏好以改进我们的服务。并且这些信息能够提升我们对您的评价，使您可以获得我们提供的更高层级的服务。\n' +
                    '    我们非常重视您的个人信息安全。平台将采取不低于一般行业惯例对于通过您的授权所获取的信息进行保护，并以一切商业上的合理努力确保这些信息的安全。我们组织内部也将根据国家相关法律、法规、规章等制度制定严格的隐私和安全准则，设立个人信息保护责任部门，并要求全体员工知晓和遵守，对可能接触到您的信息的工作人员采用最小够用授权原则，即仅允许有必要知晓这些信息的本产品和其他关联公司的员工在采取合理的措施验证身份之后，访问或修改这些信息。同时，我们会严格要求他们履行保密及安全义务，如果未能履行这些义务，其会被追究法律责任或被终止与本产品的合作关系。通常，平台的员工或与平台合作的第三方并不会查看在平台系统中存储的用户信息，当我们得知您可能违反了平台的产品及服务条款时，为了及时提醒您以免您留下不良信用记录，我们会根据本《隐私政策》或您与我们另行达成的约定使用您的信息。\n' +
                    '    基于防火墙或其他安全软件可能发生故障，或按照当前商业上可以采取的安全手段也难以避免或及时消除的故障及破坏，将可能导致您的信息遭到外部访问、窃取或删除，此等情形下我们不承担相应的责任。\n' +
                    '    因不可抗力所导致的用户资料及信息泄露（包含但不限于黑客攻击、第三方导致的系统缺陷等），我们不承担相应的责任。\n' +
                    '    您在我们平台的账号和密码等账户信息是非常重要的，要妥善保管。使用您的账号、密码等账户信息登录平台进行的操作视为您个人的操作，您应当承担该等操作产生的一切法律后果。如果您因为自身的保管不善、委托他人使用或者其他个人原因导致您权益受损，我们将不承担相关责任。\n' +
                    '    我们将根据相关法律、法规、政策的要求及提供服务的需要不时地修改本政策并于平台首页发布，您应当注意本政策的变更。若您在本政策公告变更后继续使用本服务，表示您已充分阅读、理解并接受修改后的政策内容，也将遵循修改后的政策使用本平台的服务；同时就您在隐私政策和具体规则修订前通过本平台进行的交易及其效力，视为您已同意并已按照本隐私政策及有关规则进行了相应的授权和追认。\n' +
                    '    本《隐私政策》未尽事项按照平台现有及不时发布的各项规则执行。\n' +
                    '    如《隐私政策》中的任何一条或多条被确认为无效，该无效条款并不影响本隐私政策其他条款的效力。\n' +
                    ' \n  </div>',
                theme: 'round-button',
            }).then(() => {
                // on close
            });
            this.checked = false
        },

        next() {

            const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            if (this.phone === '' || !phoneReg.test(this.phone)) {
                Toast('请输入正确的电话号码');
                return;
            }

            // eslint-disable-next-line no-unreachable
            if (this.price === '') {
                Toast('请输入贷款金额');
                return;
            }

            if (this.checked === '' || this.checked === false) {
                Toast('请勾选服务协议');
                return;
            }

            window.sessionStorage.setItem("loanAmount", this.price)
            window.sessionStorage.setItem("loanTime", this.selList[this.selectIndex].month)
            window.sessionStorage.setItem("phone", this.phone)
            this.loading = true;
            let data = {
                mobile: this.phone,
                verifyCode: this.code,
                code: this.$route.query.code,
            }

            setTimeout(() => {
                sendVerifyCodeAndLogin(data).then((response) => {
                    console.log(response)
                    this.loading = false
                    if (response.data.code === 200) {
                        // || response.data.data.goloan === 2
                        window.sessionStorage.setItem("toH5", response.data.data.productList)
                        console.log("去H5" + response.data.data.productList);
                        console.log("去H5" + window.sessionStorage.getItem("toH5"));
                        if (response.data.data.loginType === 1) {
                            window.sessionStorage.setItem("logined", "1");
                            this.$router.replace({ path: '/Audit' })
                        } else {
                            window.sessionStorage.setItem("goloan", response.data.data.goloan);
                            this.showDialog = true;
                            if (this.count) {
                                this.$toast("请稍等...")
                                return;
                            }
                            if (!this.timer) {
                                this.count = this.TIME_COUNT
                                this.show = false
                                this.timer = setInterval(() => {
                                    if (this.count > 1 && this.count <= this.TIME_COUNT) {
                                        this.count--
                                    } else {
                                        this.count = "";
                                        this.show = true
                                        clearInterval(this.timer) // 清除定时器
                                        this.timer = null
                                    }
                                }, 1000)
                            } else {
                                let data = {
                                    mobile: this.phone,
                                    verifyCode: this.code
                                }
                                this.showDialog = true;
                                this.sendCode()
                                sendVerifyCode(data).then((response) => {
                                    if (response.data.code === 200) {
                                        window.sessionStorage.setItem("loanAmount", this.price)
                                        window.sessionStorage.setItem("loanTime", this.selList[this.selectIndex].month)
                                        window.sessionStorage.setItem("phone", this.phone)
                                    } else {
                                        Toast(response.data.msg);
                                    }
                                }).catch((e) => {
                                    Toast("服务器异常:" + e)
                                    this.showDialog = false;
                                })
                            }
                        }
                    } else {
                        Toast('服务器异常');
                    }
                }).catch((e) => {
                    Toast("服务器异常:" + e)
                    this.loading = false;
                })
            }, 1000);
        },


        login: function () {
            const parm = {
                code: this.$route.query.code,
                mobile: this.phone,
                verifyCode: this.$route.query.verifyCode,
            }
            return login(parm).then((res) => {
                if (res.data.code === 200) {
                    return true
                } else {
                    this.$toast("登录失败");
                    return false
                }
            }).catch(() => {
                this.$toast("登录失败");
                return false
            })
        },
        changeFlag() {
            console.log(this.checked);
            this.checked = !this.checked
        },
        inputCode() {
            const codeReg = /^[0-9]{6}$/;
            if (this.code === '' || !codeReg.test(this.code)) {
                Toast('请输入正确的验证码');
            } else {
                const data = {
                    code: window.sessionStorage.getItem("toCode"),
                    mobile: this.phone,
                    verifyCode: this.code
                };
                console.log("登录")
                login(data).then((r) => {
                    console.log(r)
                    if (r.data.code === 200) {
                        window.sessionStorage.setItem("logined", "1");
                        if (window.sessionStorage.getItem("goloan") === "2") {
                            window.sessionStorage.setItem("logined", "1");
                            this.$router.replace({ name: 'Audit' })
                        } else {
                            this.$router.replace({ name: 'Init' })
                        }
                    } else {
                        Toast(r.data.msg)
                    }
                }).catch(() => {
                    this.$toast("服务器异常")
                }
                )
            }
        },

        onBeforeClose(action, done) {
            if (action === "confirm") {
                return done(false);
            } else {
                return done();
            }
        },
    }
}
</script>

<style scoped>
/deep/ .el-button--primary {
    background: #1c6ffd;
    border-color: #1c6ffd;
}

/deep/ .el-button--primary.is-active,
.el-button--primary:active {
    background: #1c6ffd;
    border-color: #1c6ffd;
}

/deep/ .el-checkbox__inner {
    width: 15px;
    height: 15px;
    z-index: 0;
}

/deep/ .el-button--primary:focus,
.el-button--primary:hover {
    background: #1c6ffd;
    border-color: #1c6ffd;
}

/deep/ .el-checkbox__inner::after {
    width: 4px;
    height: 7px;
}

/deep/ .el-input__inner:focus {
    border: #1c6ffd 1px solid;
}

/deep/ .van-dialog__confirm,
.van-dialog__confirm:active {
    color: #1c6ffd;
}

/deep/ .el-checkbox__inner:hover {
    border-color: #1c6ffd;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: 400;
    vertical-align: baseline;
}

.versionOneBox {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.versionOneBox>div {
    background-color: #fff;
}

.top_head {
    box-sizing: border-box;
    width: 10rem;
    height: 5.92rem;
    background: linear-gradient(90deg, #4883fa, #1964ff 100%, #0e69ff 0);
    padding: 0.48rem;
    text-align: left;

}

.top_head img {
    width: 4.05rem;
    display: block;
    pointer-events: none;
}

.text1 {
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: .45rem;
    margin-top: 0.75rem;
    margin-bottom: 0.37rem;
}

.qian {
    font-size: 1.44rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: 700;
    color: #fff;
    line-height: 1.28rem;
}

.limit {
    width: 8.96rem;
    border-top: 0.01rem solid hsla(0, 0%, 100%, .38);
    display: flex;
    align-items: center;
    margin-top: 0.24rem;
    padding: 0.32rem 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 0.24rem;
}

.limit div {
    padding: 0.05rem 0.21rem;
    background: linear-gradient(90deg, #ffd784, #ffbc45);
    border-radius: 0.24rem;
    font-size: .27rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 700;
    color: #654203;
    margin-right: 0.16rem
}

.limit p {
    font-size: .32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: .45rem;
}

.box {
    height: calc(100% - 5.33rem);
    background-color: #f4f5f9!important;
    position: relative;
    border-radius: 0.45rem 0.45rem 0 0;
    top: -0.59rem;
}


.versionOneBox .box>div {
    background-color: #fff;
}
.inp_box {
    padding: 0.35rem 0.53rem;
    border-radius: 0.45rem 0.45rem 0 0;
    padding-top: 0.6rem;
}

.inp_box p {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.inp_box p span {
    font-size: .37rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #596277;
    line-height: .53rem;

}

.inp_cpl {
    margin-top: 0.64rem;
}


.input {
    background: #fff;
    border-radius: 0.69rem;
    border: 0.03rem solid #e9e9e9;
    padding-left: 0.48rem;
    position: relative;
    overflow: hidden;
}

.input img {
    float: left;
    width: 0.53rem;
    height: 0.53rem;
    margin-right: 0.19rem;
    margin-top: 0.45rem;
}

.tel {
    float: left;
    width: calc(100% - 1rem);
    padding: 0.45rem 0;
    font-size: .34666667rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333;
}

.versionFormOneIn .frombox .tel:after {
    content: "";
    display: block;
    clear: both;
}

.btnbox {
    width: 8.93rem;
    height: 1.39rem;
    background: #1c6ffd;
    border-radius: 0.69rem;
    margin-top: 0.27rem;
    line-height: 1.39rem;
    text-align: center;
    font-size: .43rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
}

i {
    font-style: normal;
}

.xieyi {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    word-break: keep-all;
    font-size: .29rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin-top: 0.32rem;
    line-height: .43rem;
}

.inp_box p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inp_box p span {
    font-size: .37rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #596277;
    line-height: .53rem;
}

.versionFormOneIn .frombox .xieyi .p2 {
    color: #0465ff;
}

.versionFormOneIn .frombox .xieyi .p1 {
    color: #c7cacd;
}

.versionFormOneIn .frombox .xieyi img {
    width: 0.37rem;
    margin-right: 0.11rem;
}

img {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: 400;
    vertical-align: baseline;
    display: block;
}

.banner {
    padding: 0.32rem 0;
    padding-left: 0.53rem;
    margin-top: 0.16rem;
}

.versionOneBox .box .banner p {
    font-size: .43rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 700;
    color: #192b4e;
    line-height: .59rem;
}

.versionOneBox .box .banner img {
    width: 9.01rem;
    margin-top: 0.27rem;
}

.versionOneBox .box .step {
    margin-top: 0.16rem;
    padding: 0.43rem 0.85rem
}

.versionOneBox .box .step .title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.versionOneBox .box .step .title img {
    width: 0.48rem;
}

.versionOneBox .box .step .title p {
    font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 700;
    color: #1a2b4d;
    line-height: .67rem;
    margin: 0 0.21rem;
}

.versionOneBox .box .step .steps {
    height: 2.11rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0.53rem;
}

.versionOneBox .box .step .steps .item {
    text-align: center;
}

.versionOneBox .box .step .steps .item img {
    width: 0.72rem;
    display: inline-block;
}


.versionOneBox .box .step .steps .item .p1 {
    font-size: .37rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 700;
    color: #192b4e;
    line-height: .53rem;
    margin-top: 0.16rem;
}

.versionOneBox .box .step .steps .item .p2 {
    font-size: .29rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #979799;
    line-height: .43rem;
    margin-top: 0.05rem;
}

.versionOneBox .box .step .steps .sp {
    height: 100%;
    padding-top: 0.4rem;
}

.versionOneBox .box .fotban {
    width: 10rem;
    padding: 0 0.4rem;
    padding-top: 0.77333333rem;
    box-sizing: border-box;
}
</style>