<template>
  <div>
    <div class="init_d">
      <div class="init_from_box">
        <div class="init_from_title">
          <span style="color: #2a78fe; font-size: 12px">填写</span>
          <span style="color: #f5bd02; font-size: 16px; font-weight: 1000"
            >真实信息</span
          >
          <span style="color: #2a78fe; font-size: 12px">, 已有</span>
          <span style="color: #f5bd02; font-size: 16px; font-weight: 1000"
            >35.8</span
          >
          <span style="color: #2a78fe; font-size: 12px">万人成功下款</span>
        </div>

        <div class="init_from_name">
          <el-form
            label-position="left"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="真实姓名">
              <el-input
                v-model="formLabelAlign.inputName"
                :disabled="flagStatus"
                placeholder="请输入真实姓名"
              ></el-input>
            </el-form-item>
            <el-divider></el-divider>

            <el-form-item label="身份证">
              <el-input
                oninput="value=value.replace(/[^\w]/g,'')"
                maxlength="22"
                :disabled="flagStatus"
                v-model="formLabelAlign.inputCard"
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>

            <el-divider></el-divider>

            <el-form-item label="所在城市">
              <div
                @click="Openpop"
                v-if="able"
                style="
                  text-align: start;
                  padding: 0 15px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <a>{{ addr === "" ? "请选择" : addr }} </a>
                <i
                  :class="
                    !showPicker ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
                  "
                ></i>
              </div>

              <div
                v-else
                style="
                  text-align: start;
                  padding: 0 15px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  background-color: #f4f4f4;
                "
              >
                <a>{{ addr === "" ? "请选择" : addr }} </a>
              </div>
            </el-form-item>

            <el-button
              @click="VerifyIDcardBtn"
              type="primary"
              :disabled="isDisabled2"
              round
              v-if="Nextbtn"
              style="width: 100%; background-color: #2a78fe; margin-top: 20px"
              >下一步</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
    <div class="init_d" v-if="VerifyIDcard">
      <div class="init_from_box">
        <div class="init_from_title">
          <span style="color: #409eff; font-size: 12px">完善</span>
          <span style="color: #f5bd02; font-size: 16px; font-weight: 1000"
            >相关信息</span
          >
          <span style="color: #409eff; font-size: 12px">, 通过率提升</span>
          <span style="color: #f5bd02; font-size: 16px; font-weight: 1000"
            >88%</span
          >
        </div>

        <div class="init_from_name">
          <el-form
            label-position="left"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="芝麻分">
              <div
                @click="
                  sesameSeed === true
                    ? (sesameSeed = false)
                    : (sesameSeed = true)
                "
                class="init_select"
              >
                <a>{{ sesameSeedTxt }} </a>
                <i
                  :class="
                    !sesameSeed ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
                  "
                ></i>
              </div>
            </el-form-item>
            <div v-if="sesameSeed" style="padding: 10px; height: 35px">
              <div
                @click="setSesameSeed(sesameSeedTxtList.a)"
                :class="
                  sesameSeedTxt === sesameSeedTxtList.a
                    ? 'init_button_select_top_left_s'
                    : 'init_button_select_top_left'
                "
              >
                <a>{{ sesameSeedTxtList.a }}</a>
              </div>
              <div
                @click="setSesameSeed(sesameSeedTxtList.b)"
                :class="
                  sesameSeedTxt === sesameSeedTxtList.b
                    ? 'init_button_select_top_center_s'
                    : 'init_button_select_top_center'
                "
              >
                <a>{{ sesameSeedTxtList.b }}</a>
              </div>
              <div
                @click="setSesameSeed(sesameSeedTxtList.c)"
                :class="
                  sesameSeedTxt === sesameSeedTxtList.c
                    ? 'init_button_select_top_right_s'
                    : 'init_button_select_top_right'
                "
              >
                <a>{{ sesameSeedTxtList.c }}</a>
              </div>
            </div>

            <!-- <el-form-item label="信用卡">
              <div @click="creditCard === true ? creditCard = false : creditCard = true" class="init_select">
                <a>{{ creditCardTxt }} </a>
                <i :class="!creditCard ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
              </div>
            </el-form-item>

            <div v-if="creditCard" style="padding: 10px; height: 35px">
              <div @click="setCreditCard(creditCardTxtList.a)"
                :class="creditCardTxt === creditCardTxtList.a ? 'init_button_select_top_left_s' : 'init_button_select_top_left'">
                <a>{{ creditCardTxtList.a }}</a>
              </div>
              <div @click="setCreditCard(creditCardTxtList.b)"
                :class="creditCardTxt === creditCardTxtList.b ? 'init_button_select_top_center_s' : 'init_button_select_top_center'">
                <a>{{ creditCardTxtList.b }}</a>
              </div>
            </div> -->

            <el-divider></el-divider>
            <el-form-item label="公积金缴纳">
              <div
                @click="fund === true ? (fund = false) : (fund = true)"
                class="init_select"
              >
                <a>{{ fundTxt }} </a>
                <i
                  :class="!fund ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                ></i>
              </div>
            </el-form-item>

            <div v-if="fund" style="padding: 10px; height: 35px">
              <div
                @click="setFund(fundTxtList.a)"
                :class="
                  fundTxt === fundTxtList.a
                    ? 'init_button_select_top_left_s'
                    : 'init_button_select_top_left'
                "
              >
                <a>{{ fundTxtList.a }}</a>
              </div>
              <div
                @click="setFund(fundTxtList.b)"
                :class="
                  fundTxt === fundTxtList.b
                    ? 'init_button_select_top_center_s'
                    : 'init_button_select_top_center'
                "
              >
                <a>{{ fundTxtList.b }}</a>
              </div>

              <div
                @click="setFund(fundTxtList.c)"
                :class="
                  fundTxt === fundTxtList.c
                    ? 'init_button_select_top_right_s'
                    : 'init_button_select_top_right'
                "
              >
                <a>{{ fundTxtList.c }}</a>
              </div>
            </div>
            <el-divider></el-divider>
            <el-form-item label="房产情况">
              <div
                @click="house === true ? (house = false) : (house = true)"
                class="init_select"
              >
                <a>{{ houseTxt }} </a>
                <i
                  :class="!house ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                ></i>
              </div>
            </el-form-item>
            <div v-if="house" style="padding: 10px; height: 35px">
              <div
                @click="setHouse(houseTxtList.a)"
                :class="
                  houseTxt === houseTxtList.a
                    ? 'init_button_select_top_left_s'
                    : 'init_button_select_top_left'
                "
              >
                <a>{{ houseTxtList.a }}</a>
              </div>
              <div
                @click="setHouse(houseTxtList.b)"
                :class="
                  houseTxt === houseTxtList.b
                    ? 'init_button_select_top_center_s'
                    : 'init_button_select_top_center'
                "
              >
                <a>{{ houseTxtList.b }}</a>
              </div>
              <div
                @click="setHouse(houseTxtList.c)"
                :class="
                  houseTxt === houseTxtList.c
                    ? 'init_button_select_top_right_s'
                    : 'init_button_select_top_right'
                "
              >
                <a>{{ houseTxtList.c }}</a>
              </div>
            </div>
            <el-divider></el-divider>

            <el-form-item label="车产情况">
              <div
                @click="car === true ? (car = false) : (car = true)"
                class="init_select"
              >
                <a>{{ carTxt }}</a>
                <i
                  :class="!car ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                ></i>
              </div>
            </el-form-item>

            <div v-if="car" style="padding: 10px; height: 35px">
              <div
                @click="setCar(carTxtList.a)"
                :class="
                  carTxt === carTxtList.a
                    ? 'init_button_select_top_left_s'
                    : 'init_button_select_top_left'
                "
              >
                <a>{{ carTxtList.a }}</a>
              </div>
              <div
                @click="setCar(carTxtList.b)"
                :class="
                  carTxt === carTxtList.b
                    ? 'init_button_select_top_center_s'
                    : 'init_button_select_top_center'
                "
              >
                <a>{{ carTxtList.b }}</a>
              </div>

              <div
                @click="setCar(carTxtList.c)"
                :class="
                  carTxt === carTxtList.c
                    ? 'init_button_select_top_right_s'
                    : 'init_button_select_top_right'
                "
              >
                <a>{{ carTxtList.c }}</a>
              </div>
            </div>

            <el-divider></el-divider>
          </el-form>
        </div>
      </div>
    </div>

    <div
      v-if="
        creditCardTxt !== plSelect &&
        sesameSeedTxt !== plSelect &&
        fundTxt !== plSelect
      "
      class="init_d"
    >
      <div class="init_from_box">
        <div class="init_from_title">
          <span style="color: #409eff; font-size: 12px">请填写</span>
          <span style="color: #f5bd02; font-size: 16px; font-weight: 1000"
            >真实资产</span
          >
          <span style="color: #409eff; font-size: 12px">, 有助于快速下款</span>
        </div>

        <div class="init_from_name">
          <el-divider></el-divider>
        </div>
      </div>
    </div>

    <div
      v-if="
        houseTxt !== plSelect &&
        carTxt !== plSelect &&
        sesameSeedTxt !== plSelect &&
        fundTxt !== plSelect
      "
      class="init_d"
    >
      <div class="init_from_box">
        <div class="init_from_title">
          <span style="color: #409eff; font-size: 12px">恭喜你已完成</span>
          <span style="color: #f5bd02; font-size: 16px; font-weight: 1000"
            >95%</span
          >
          <span style="color: #409eff; font-size: 12px">的认证步骤</span>
        </div>

        <div class="init_from_name">
          <el-form
            label-position="left"
            label-width="120px"
            :model="formLabelAlign"
          >
            <el-form-item label="贷款金额">
              <div
                @click="price === true ? (price = false) : (price = true)"
                class="init_select"
              >
                <a>{{ priceTxt }}</a>
                <i
                  :class="!price ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                ></i>
              </div>
            </el-form-item>

            <div v-if="price" style="padding: 10px; height: 70px">
              <div
                @click="setPrice(priceTxtList.a)"
                :class="
                  priceTxt === priceTxtList.a
                    ? 'init_button_select_top_left_s'
                    : 'init_button_select_top_left'
                "
              >
                <a>{{ priceTxtList.a }}</a>
              </div>
              <div
                @click="setPrice(priceTxtList.b)"
                :class="
                  priceTxt === priceTxtList.b
                    ? 'init_button_select_top_center_s'
                    : 'init_button_select_top_center'
                "
              >
                <a>{{ priceTxtList.b }}</a>
              </div>
              <div
                @click="setPrice(priceTxtList.c)"
                :class="
                  priceTxt === priceTxtList.c
                    ? 'init_button_select_top_right_s'
                    : 'init_button_select_top_right'
                "
              >
                <a>{{ priceTxtList.c }}</a>
              </div>

              <div
                @click="setPrice(priceTxtList.d)"
                :class="
                  priceTxt === priceTxtList.d
                    ? 'init_button_select_bottom_left_s'
                    : 'init_button_select_bottom_left'
                "
              >
                <a>{{ priceTxtList.d }}</a>
              </div>

              <div
                @click="setPrice(priceTxtList.e)"
                :class="
                  priceTxt === priceTxtList.e
                    ? 'init_button_select_bottom_center_s'
                    : 'init_button_select_bottom_center'
                "
              >
                <a>{{ priceTxtList.e }}</a>
              </div>
            </div>

            <el-divider></el-divider>
            <el-form-item label="职业">
              <div
                @click="job === true ? (job = false) : (job = true)"
                class="init_select"
              >
                <a>{{ jobTxt }} </a>
                <i
                  :class="!job ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                ></i>
              </div>
            </el-form-item>
            <div v-if="job" style="padding: 10px; height: 70px">
              <div
                @click="setJob(jobTxtList.a)"
                :class="
                  jobTxt === jobTxtList.a
                    ? 'init_button_select_top_left_s'
                    : 'init_button_select_top_left'
                "
              >
                <a>{{ jobTxtList.a }}</a>
              </div>
              <div
                @click="setJob(jobTxtList.b)"
                :class="
                  jobTxt === jobTxtList.b
                    ? 'init_button_select_top_center_s'
                    : 'init_button_select_top_center'
                "
              >
                <a>{{ jobTxtList.b }}</a>
              </div>
              <div
                @click="setJob(jobTxtList.c)"
                :class="
                  jobTxt === jobTxtList.c
                    ? 'init_button_select_top_right_s'
                    : 'init_button_select_top_right'
                "
              >
                <a>{{ jobTxtList.c }}</a>
              </div>

              <div
                @click="setJob(jobTxtList.d)"
                :class="
                  jobTxt === jobTxtList.d
                    ? 'init_button_select_bottom_left_s'
                    : 'init_button_select_bottom_left'
                "
              >
                <a>{{ jobTxtList.d }}</a>
              </div>
              <div
                @click="setJob(jobTxtList.e)"
                :class="
                  jobTxt === jobTxtList.e
                    ? 'init_button_select_bottom_center_s'
                    : 'init_button_select_bottom_center'
                "
              >
                <a>{{ jobTxtList.e }}</a>
              </div>

              <div
                @click="setJob(jobTxtList.f)"
                :class="
                  jobTxt === jobTxtList.f
                    ? 'init_button_select_bottom_right_s'
                    : 'init_button_select_bottom_right'
                "
              >
                <a>{{ jobTxtList.f }}</a>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <div style="padding: 25px" v-if="Idcardstatus">
      <div style="text-align: left; padding-left: 10px; margin-bottom: 15px">
        <el-checkbox v-model="radio">
          本人已阅读并同意 <a @click="showBook()">《贷款知情书》</a>
        </el-checkbox>
      </div>
      <el-button
        @click="next"
        type="primary"
        :disabled="isDisabled"
        round
        style="width: 100%; background-color: #2a78fe"
        >下一步</el-button
      >
    </div>

    <el-dialog
      title="极速下款"
      :visible.sync="showDialog"
      width="300px"
      :show-close="true"
      center
    >
      <div class="init_dialog_content">
        <span
          >根据你当前的资料信息
          <br />
          预估可借1万额度
        </span>
      </div>
      <span slot="footer">
        <el-button
          @click="toAudit"
          type="primary"
          round
          style="width: 80%"
          size="small"
          >立即前往提现</el-button
        >
        <div style="height: 5px"></div>
        <el-button
          @click="showDialog = false"
          round
          style="width: 80%"
          size="small"
          >继续填资申请(高额)</el-button
        >
        <div style="height: 10px"></div>
      </span>
    </el-dialog>
    <van-popup v-model="showPicker" round position="bottom">
      <van-area
        title="请选择城市"
        show-toolbar
        :area-list="areaList"
        :columns-num="2"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import Vue from "vue";
import debounce from "lodash/debounce";
// import {Area, Dialog, Popup, } from 'vant';
import { Area, Dialog, Popup, Toast } from "vant";
import "vant/lib/popup/style";
import "vant/lib/picker/style";
// eslint-disable-next-line no-unused-vars
import { areaList } from "@vant/area-data";
import { addGolanUser, idCarValid, UserChoose } from "@/api/api"; //idCarValid

Vue.use(Popup);
// eslint-disable-next-line no-unused-vars
Vue.use(Area);

export default {
  name: "Init",

  components: {},

  data() {
    return {
      able: true,
      //一推多判定
      chooseFlag: "",
      reqNmu: 0,
      VerifyIDcard: false,
      flagStatus: false,
      isDisabled: false,
      areaList: areaList,
      value: "",
      showPicker: false,
      radio: false,
      otherInfo: false,
      price: false,
      isDisabled2: false,
      job: false,
      showDialog: false,
      Nextbtn: true,
      priceTxt: "请选择 ",
      jobTxt: "请选择 ",
      addr: "",
      //省
      province: "",

      priceTxtList: {
        a: "1万 ",
        b: "3万 ",
        c: "5万 ",
        d: "10万 ",
        e: "10万以上 ",
      },

      jobTxtList: {
        a: "上班族 ",
        b: "自由职业 ",
        c: "企业主 ",
        d: "个体户 ",
        e: "公务员 ",
        f: "其他 ",
      },

      house: false,
      car: false,
      socSec: false,
      insurance: false,

      houseTxt: "请选择 ",
      houseTxtList: {
        a: "无房产 ",
        b: "有房不抵押 ",
        c: "有房可抵押 ",
      },

      carTxt: "请选择 ",
      carTxtList: {
        a: "无车产 ",
        b: "有车不抵押 ",
        c: "有车可抵押 ",
      },

      socSecTxt: "请选择 ",
      socSecTxtList: {
        a: "无社保 ",
        b: "未满6个月 ",
        c: "6个月以上 ",
      },

      insuranceTxt: "请选择 ",
      insuranceList: {
        a: "无保险保单 ",
        b: "缴纳未满一年 ",
        c: "缴纳一年以上 ",
      },

      sesameSeed: false,
      creditCard: false,
      fund: false,

      sesameSeedTxt: "请选择 ",
      sesameSeedTxtList: {
        a: "600分以下 ",
        b: "600-700分 ",
        c: "700分以上 ",
      },

      creditCardTxt: "请选择 ",
      creditCardTxtList: {
        a: "无 ",
        b: "有 ",
      },

      plSelect: "请选择 ",

      fundTxt: "请选择 ",
      fundTxtList: {
        a: "无公积金 ",
        b: "未满6个月 ",
        c: "6个月以上 ",
      },

      infoSucc: false,
      // showDialog: true,
      count: 10,
      formLabelAlign: {
        inputName: "",
        inputCard: "",
      },

      pass: false,
      Idcardstatus: false,
      show3: false,
    };
  },

  watch: {
    test: function () {},
  },
  created() {
    console.log("---", debounce);
    let Userkey = this.$route.query.key;
    if (typeof Userkey === "undefined") {
      //未联登

      if (window.sessionStorage.getItem("logined") !== "1") {
        this.$router.replace({ path: "/" });
        Toast("未登录...");
        return;
      }
      this.showBook();
    } else {
      //联登
      axios({
        method: "get",
        url: `https://app.fangxin.plus/apinew/app/auth?key=${Userkey}`,
      })
        .then((res) => {
          if (res.data.code === 200) {
            let r = res.data.data;
            window.sessionStorage.setItem("toCode", r.toCode);
            window.sessionStorage.setItem("phone", r.phone);
            window.sessionStorage.setItem("loanTime", r.loanTime);
            window.sessionStorage.setItem("toH5", r.productList);
            window.sessionStorage.setItem("goloan", r.goloan);
            window.sessionStorage.setItem("logined", 1);
          } else {
            this.flagStatus = true;
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    }
  },

  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", this.disableBrowserBack);
    this.Pushstatus();
  },

  methods: {
    //推送状态
    Pushstatus() {
      UserChoose().then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.chooseFlag = res.data.flag;
        } else {
          this.$toast("未知推送状态");
        }
      });
    },
    toAudit: function () {
      setTimeout(() => {
        this.$router.replace({ path: "/Audit" });
      }, 2000);
    },
    disableBrowserBack() {
      this.showDialog = true;
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.disableBrowserBack);
    },
    showBook() {
      Dialog.alert({
        confirmButtonColor: "#2a78fe",
        title: "贷款知情书",
        messageAlign: "left",
        allowHtml: true,
        // closeOnClickOverlay: true,
        confirmButtonText: "同意并继续",
        message:
          "<div style='height:200px;'>        放薪（以下简称“平台”）由成都盈拓数信信息科技有限公司运营，用户承诺在仔细阅读和完全接受《用户注册服务协议》（以下简称“本协议”）项下全部条款的基础上使用本平台的各项服务。用户接受本协议点击\"同意、同意并注册\"按钮或其他类似意思表示完成注册即表示完全接受以上述及的所有条款。\n" +
          "        本平台可能根据法律法规的要求或业务运营的需要，对本协议服务条款不时进行修改。除非另有规定，否则任何修改将在该等修改内容发布之时立即生效，您对本平台的注册、使用、及继续使用均表明您接受此等修改。如果您不同意本协议服务条款（包括本平台可能不定时对其或其中引述的其他规则所进行的任何修改）的任一规定，则请勿注册或使用本平台，或您可以主动取消本平台提供的服务。\n" +
          "        为了便于您了解适用于您的条款和条件，我们将在平台上发布对本协议服务条款的修改，您应不时地审阅本协议服务条款以及经参引而并入其中的其他规则。\n" +
          "一、服务内容\n" +
          "    1、本平台为信息技术服务平台，您可通过本平台发布自己的借款需求，经您的合法授权后，放款平台或信贷员可在本平台查看并获取您的借款信息和联系方式，并与您联系协商借贷事宜。\n" +
          "    2、本平台仅为借贷需求双方提供交易机会与信息服务，不参与借款行为以及其他交易行为，不提供资金往来或托管服务。您应审慎对待自己的交易行为，您与出借人之间发生的借贷行为均与本平台无关，本平台不对借款双方以及第三方借贷渠道提供任何形式的担保。\n" +
          "    3、平台的具体服务内容由我们根据实际情况提供并不时更新，包括但不限于信息、图片、文章、评论、链接等，我们将定期或不定期根据用户的意愿以电子邮件、短信、电话或站内信等方式为用户提供活动信息，并向用户提供相应服务，具体服务以平台实际展示为准。我们对提供的服务拥有最终解释权。\n" +
          "    4、平台服务仅供个人用户使用。除我们书面同意，您或其他用户均不得将平台上的任何信息用于商业目的。\n" +
          "    5、您使用平台服务时所需的相关的设备以及网络资源等（如个人电脑、手机及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均由您自行负担。\n" +
          "二、信息提供和隐私保护\n" +
          "    1、您在注册账号或使用本平台服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，用户需要填写真实的身份信息。若您填写的信息不完整，则无法使用本平台服务或在使用过程中受到限制。您有义务保证密码和帐号的安全，并保证账号及密码仅供自己使用。您同意不断更新注册资料，符合及时、详尽准确的要求，所有原始键入的资料将引用为注册资料。\n" +
          "    2、 您在访问、使用平台或申请使用平台服务时，必须提供本人真实的个人信息，且您应该根据实际变动情况及时更新个人信息。保护用户隐私是我们的重点原则，我们通过各种技术手段和强化内部管理等办法提供隐私保护服务功能，充分保护您的个人信息安全。\n" +
          "    3、平台不负责审核您提供的个人信息的真实性、准确性或完整性，因信息不真实、不准确或不完整而引起的任何问题及其后果，由您自行承担，且您应保证我们免受由此而产生的任何损害或责任。若我们发现您提供的个人信息是虚假、不准确或不完整的，我们有权自行决定终止向您提供服务。\n" +
          "    4、您已明确授权，为提供服务、履行协议、解决争议、保障交易安全等目的，我们对您提供的、我们自行收集的及通过第三方收集的您的个人信息、您申请服务时的相关信息、您在使用服务时储存在平台的非公开内容以及您的其他个人资料（以下简称“个人资料”）享有留存、整理加工、使用和披露的权利，且您明确授权平台及其关联公司及其指定的第三方可留存、整理及加工本次获取的本人信息用于平台及其关联公司及其指定的第三方为您提供的包括但不限于营销等其他服务中，具体方式包括但不限于：\n" +
          "        （1）出于为您提供服务的需要在本平台公示您的个人资料；\n" +
          "        （2）由人工或自动程序对您的个人资料进行获取、评估、整理、存储；\n" +
          "        （3）使用您的个人资料以改进本平台的设计和推广；\n" +
          "        （4）使用您提供的联系方式与您联络并向您传递有关服务和管理方面的信息；\n" +
          "        （5）对您的个人资料进行分析整合并向为您提供服务的第三方提供为完成该项服务必要的信息。当为您提供服务的第三方与您电话核实信息时，为保证为您服务的质量，你同意平台对上述核实电话进行录音。\n" +
          "        （6）在您违反与我们或我们的其他用户签订的协议时，披露您的个人资料及违约事实，将您的违约信息写入黑名单并与必要的第三方共享数据，以供我们及第三方审核、追索之用。\n" +
          "        （7）其他必要的使用及披露您个人资料的情形。\n" +
          "    为更好地为您提供服务，您同意并授权平台可与其合作的第三方进行联合研究，并可将通过本协议获得的您的信息投入到该等联合研究中。但平台与其合作的第三方在开展上述联合研究前，应要求其合作的第三方对在联合研究中所获取的您的信息予以保密。\n" +
          "    您在此授权我们及关联公司、合作伙伴可以通过短信或邮箱向您发送商业信息。\n" +
          "    为向您提供本平台信息技术服务之目的，本平台会将您的借款信息及联系方式展示给信贷员及放款机构，您在此授权本平台可进行上述操作。\n" +
          "    我们保证采用行业惯例以保护您的资料，但您理解，鉴于技术限制，我们无法确保用户的个人信息完全不被泄露。\n" +
          "    我们不会向与您无关的第三方恶意出售或免费提供您的个人资料，\n" +
          "    但下列情况除外： \n" +
          "        （1）事先获得您的明确授权；\n" +
          "        （2）按照相关司法机构或政府主管部门的要求；\n" +
          "        （3）以维护我们合法权益之目的； \n" +
          "        （4）维护社会公众利益； \n" +
          "        （5）只有共享您的信息，才能提供您需要的服务和（或）产品。\n" +
          "        （6）相应的法律、法规要求及程序服务需要平台提供用户的个人资料。\n" +
          "    本授权可在您接受平台（或其委托或合作的第三方）提供服务的过程中多次使用，本平台（或其委托或合作的第三方）及合作机构自您签署本授权之日起可多次依据本授权而操作执行本授权项下的活动无需您另行授权。\n" +
          "    您确认已充分被告知、了解并接受上述授权的法律后果。\n" +
          "三、使用准则\n" +
          "    用户在申请使用平台提供的网络服务时，必须向平台提供准确的个人资料，如个人资料有任何变动，必须及时更新。若您填写的信息不完整或不准确，则无法使用本平台服务或在使用过程中受到限制。\n" +
          "    用户注册成功后，平台将给予您一个用户账号及相应的密码，该用户账号和密码由用户负责保管；用户应当对以其用户账号进行的所有言论、行为、活动和事件承担法律责任。\n" +
          "     用户的账号名称应当符合《互联网用户账号名称管理规定》之规定，在账号名称、头像和简介等注册信息中不得出现违法和不良信息。平台对用户提交的账号名称、头像和简介等注册信息有权进行审核，对含有违法和不良信息的，不予注册。对于已经注册的含有违法或者不良信息的账号名称、头像、简介，平台有权采取通知限期改正、暂停使用、注销登记等措施，造成的后果由用户自行承担。对冒用、关联机构或社会名人注册账号名称的，平台有权注销其账号，并向互联网信息内容主管部门报告。\n" +
          "    平台对用户提交的账号名称等信息予以审核通过并不代表平台对其予以任何批准、许可、授权、同意或者支持，用户仍然应当自行承担其法律责任，且平台保留进行后续不时审核并予以处理的权利。\n" +
          "    用户在使用平台某些服务时，平台需要对用户身份进行特别验证，具体的验证方式以各服务模块页面显示为准。\n" +
          "    用户不得利用平台的服务从事下列危害互联网信息网络安全的活动：\n" +
          "        (1) 未经允许，进入互联网信息网络或者使用互联网信息网络资源；\n" +
          "        (2) 未经允许，对互联网信息网络功能进行删除、修改或者增加；\n" +
          "        (3) 未经允许，对进入互联网信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；\n" +
          "        (4) 故意制作、传播计算机或手机病毒等破坏性程序；\n" +
          "        (5) 其他危害互联网信息网络安全的行为。 \n" +
          "    如您的操作影响系统总体稳定性或完整性，我们将暂停或终止您的操作，直到相关问题得到解决。\n" +
          "四、免责声明\n" +
          "    1、平台是一个开放平台，用户将文章或照片等个人资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。作为网络服务的提供者，我们对用户在任何论坛、个人主页或其它互动区域提供的任何陈述、声明或内容均不承担责任。您明确同意使用平台服务所存在的风险或产生的一切后果将完全由您自身承担，我们对上述风险或后果不承担任何责任。\n" +
          "    2、 您违反本注册协议、违反道德或法律的，侵犯他人权利（包括但不限于知识产权）的，我们不承担任何责任。同时，我们对任何第三方通过平台发送服务或包含在服务中的任何内容不承担责任。\n" +
          "    3、 对您、其他用户或任何第三方发布、存储或上传的任何内容或由该等内容导致的任何损失或损害，我们不承担责任。 \n" +
          "    4、对任何第三方通过平台可能对您造成的任何错误、中伤、诽谤、诬蔑、不作为、谬误、淫秽、色情或亵渎，我们不承担责任。 \n" +
          "    5、 对黑客行为、计算机或手机病毒、或因您保管疏忽致使帐号、密码被他人非法使用、盗用、篡改的或丢失，或由于与本APP站链接的其它网站或APP所造成您个人资料的泄露，或您因其他非平台原因造成的损失，我们不承担责任。如您发现任何非法使用用户帐号或安全漏洞的情况，请立即与我们联系。\n" +
          "    6、 因任何非平台原因造成的网络服务中断或其他缺陷，我们不承担任何责任。\n" +
          "    7、 我们不保证服务一定能满足您的要求；不保证服务不会中断，也不保证服务的及时性、安全性、准确性。\n" +
          "    8、平台不以提供服务方的身份参与任何借贷行为，平台并不实质性介入您与第三方之间的交易，对此，您充分理解并认可。平台不对任何借贷行为进行背书或担保。平台提供的服务中不带有对平台中的任何用户、任何交易的任何保证或条件，无论是明示、默示或法定的，因此平台及其股东、创建人、高级职员、董事、代理人、关联公司、母公司、子公司和雇员等均不能完全保证网站内容的真实性、充分性、及时性、可靠性、完整性和有效性，并且免除任何由此引起的法律责任。\n" +
          "五、服务变更、中断或终止\n" +
          "    1、 如因升级的需要而需暂停服务、或调整服务内容，我们将尽可能在平台上进行通告。由于用户未能及时浏览通告而造成的损失，我们不承担任何责任。 \n" +
          "    2、您明确同意，我们保留根据实际情况随时调整平台提供的服务内容、种类和形式，或自行决定授权第三方向您提供原本我们提供的服务。因业务调整给您或其他用户造成的损失，我们不承担任何责任。同时，我们保留随时变更、中断或终止平台全部或部分服务的权利。 \n" +
          "    3、 发生下列任何一种情形，我们有权单方面中断或终止向您提供服务而无需通知您，且无需对您或第三方承担任何责任：\n" +
          "        (1) 您提供的个人资料不真实； \n" +
          "        (2) 您违反本注册协议；\n" +
          "        (3) 未经我们书面同意，将平台平台用于商业目的。 \n" +
          "    4、您可随时通知我们终止向您提供服务或直接取消平台服务。自您终止或取消服务之日起，我们不再向您承担任何形式的责任。\n" +
          "六、知识产权及其它权利\n" +
          "    1、对您在平台发布或以其它方式传播的内容，您作如下声明和保证：\n" +
          "        (1)对于该等内容，您具有所有权或使用权； \n" +
          "        (2)该等内容是合法的、真实的、准确的、非误导性的； \n" +
          "        (3)使用和发布此等内容或以其它方式传播此等内容不违反本注册协议，也不侵犯任何人或实体的任何权利或造成对任何人或实体的伤害。 \n" +
          "    3、未经相关内容权利人的事先书面同意，您不得擅自复制、传播在平台的该等内容，或将其用于任何商业目的，所有这些资料或资料的任何部分仅可作为个人或非商业用途而保存在某台计算机或其他电子设备内。否则，我们及/或权利人将追究您的法律责任。 \n" +
          "    4、您在平台发布或传播的自有内容或具有使用权的内容，您特此同意如下： \n" +
          "        (1)授予我们使用、复制、修改、改编、翻译、传播、发表此等内容，从此等内容创建派生作品，以及在全世界范围内通过任何媒介（现在已知的或今后发明的）公开展示和表演此等内容的权利； \n" +
          "        (2)授予我们及其关联方和再许可人一项权利，可依他们的选择而使用用户有关此等内容而提交的名称；\n" +
          "        (3)授予我们在第三方侵犯您在平台的权益、或您发布在平台的内容情况下，依法追究其责任的权利（但这并非我们的义务）；\n" +
          "七、特别约定\n" +
          "    1、您使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方的合法权益的情形时，我们有权直接删除该等违反规定之信息，并可以暂停或终止向您提供服务。 \n" +
          "    2、若您利用平台服务从事任何违法或侵权行为，由您自行承担全部责任，因此给我们或任何第三方造成任何损失，您应负责全额赔偿，并使我们免受由此产生的任何损害。 \n" +
          "    3、 您同意我们通过重要页面的公告、通告、电子邮件以及常规信件的形式向您传送与平台服务有关的任何通知和通告。同时，平台保留对用来申请平台用户注册的电子邮箱投放商业性广告的权利。\n" +
          "    4、 本注册协议之效力、解释、执行均适用中华人民共和国法律。 \n" +
          "    5、若非平台更新本协议，您再确认同意、签署本协议后，其效力将及于您此时及未来登陆平台时所有操作。\n" +
          "    6、您在本协议项下对本公司的授权将视为对本公司及本公司之关联公司的授权。本公司及本公司关联公司均可凭借您的授权及本协议约定执行相关操作。 \n" +
          "    7、 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向本平台所在地人民法院提起诉讼。 \n" +
          "    8、本注册协议中的标题仅为方便而设，不影响对于条款本身的解释。本注册协议最终解释权归平台所有。\n",
      }).then(() => {
        // on close
      });
      this.checked = false;
    },

    VerifyIDcardBtn() {
      if (this.reqNmu < 2) {
        const nameReg = /^[\u4e00-\u9fa5]{1,}((·[\u4e00-\u9fa5]{1,}){0,3})$/;
        const cardReg = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;
        if (
          this.formLabelAlign.inputName === "" ||
          !nameReg.test(this.formLabelAlign.inputName)
        ) {
          this.$toast("请输入正确的姓名");
          return;
        }
        if (!cardReg.test(this.formLabelAlign.inputCard)) {
          this.$toast("请输入正确的身份证");
          return;
        }
        if (this.addr == "") {
          this.$toast("请选择归属城市");
          return;
        }

        ++this.reqNmu;
        this.isDisabled2 = true;

        idCarValid({
          idCar: this.formLabelAlign.inputCard,
          name: this.formLabelAlign.inputName,
          phone: window.sessionStorage.getItem("phone"),
        }).then((res) => {
          if (res.data.code == 200) {
            this.Nextbtn = false;
            this.flagStatus = true;
            this.VerifyIDcard = true;
            this.Idcardstatus = true;
            this.able = false;
          } else {
            this.isDisabled2 = false;

            this.$toast(res.data.msg);
          }
        });
      } else {
        // Getproduct(window.sessionStorage.getItem('toCode')).then(res => {
        // (res.data.data.id).then(response => {
        //     console.log(response);
        //     window.location.href = res.data.data.url
        //   })
        // })
      }
    },

    onConfirm(value) {
      this.addr = value[1].name;
      this.province = value[0].name;
      this.showPicker = false;
    },
    setSesameSeed(val) {
      this.sesameSeedTxt = val;
      if (this.sesameSeed) {
        this.sesameSeed = false;
      }
    },

    setFund(val) {
      this.fundTxt = val;
      if (this.fund) {
        this.fund = false;
      }
    },

    setHouse(val) {
      this.houseTxt = val;
      if (this.house) {
        this.house = false;
      }
    },

    setCar(val) {
      this.carTxt = val;
      if (this.car) {
        this.car = false;
      }
    },

    setPrice(val) {
      this.priceTxt = val;
      if (this.price) {
        this.price = false;
      }
    },

    setJob(val) {
      this.jobTxt = val;
      if (this.job) {
        this.job = false;
      }
    },

    next() {
      const nameReg = /^[\u4e00-\u9fa5]{1,}((·[\u4e00-\u9fa5]{1,}){0,3})$/;

      const cardReg = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;

      if (
        this.formLabelAlign.inputName === "" ||
        !nameReg.test(this.formLabelAlign.inputName)
      ) {
        this.$toast("请输入正确的姓名");
        return;
      }

      if (!cardReg.test(this.formLabelAlign.inputCard)) {
        this.$toast("请输入正确的身份证");
        return;
      }

      if (this.addr === "") {
        this.$toast("请选择所在城市");
        return;
      }

      if (
        this.priceTxt === this.plSelect ||
        this.addr === this.plSelect ||
        this.jobTxt === this.plSelect ||
        this.fundTxt === this.plSelect ||
        this.sesameSeedTxt === this.plSelect ||
        this.houseTxt === this.plSelect ||
        this.carTxt === this.plSelect
      ) {
        this.$toast("请完善信息");
        return;
      }

      let price1 = 1;
      window.sessionStorage.setItem("loanAmount", "10000");
      if (this.priceTxt === this.priceTxtList.a) {
        price1 = 1;
        window.sessionStorage.setItem("loanAmount", "10000");
      } else if (this.priceTxt === this.priceTxtList.b) {
        price1 = 3;
        window.sessionStorage.setItem("loanAmount", "30000");
      } else if (this.priceTxt === this.priceTxtList.c) {
        price1 = 5;
        window.sessionStorage.setItem("loanAmount", "50000");
      } else if (this.priceTxt === this.priceTxtList.d) {
        price1 = 10;
        window.sessionStorage.setItem("loanAmount", "100000");
      } else if (this.priceTxt === this.priceTxtList.e) {
        price1 = 20;
        window.sessionStorage.setItem("loanAmount", "200000");
      }

      console.log(this.price);

      if (this.radio === false) {
        this.$toast("请勾选协议");
        return;
      }

      window.sessionStorage.setItem("city", this.addr);
      // eslint-disable-next-line no-unused-vars
      let isCarProduction = 0;
      if (this.carTxt === this.carTxtList.b) {
        isCarProduction = 1;
      } else if (this.carTxt === this.carTxtList.c) {
        isCarProduction = 2;
      }

      // eslint-disable-next-line no-unused-vars
      let isProvidentFund = 0;
      if (this.fundTxt === this.fundTxtList.b) {
        isProvidentFund = 1;
      } else if (this.fundTxt === this.fundTxtList.c) {
        isProvidentFund = 2;
      }

      // eslint-disable-next-line no-unused-vars
      let isRealEstate = 0;
      if (this.houseTxt === this.houseTxtList.b) {
        isRealEstate = 1;
      } else if (this.houseTxt === this.houseTxtList.c) {
        isRealEstate = 2;
      }

      // eslint-disable-next-line no-unused-vars
      let isSesame = 1;
      if (this.sesameSeedTxt === this.sesameSeedTxtList.b) {
        isSesame = 2;
      } else if (this.sesameSeedTxt === this.sesameSeedTxtList.c) {
        isSesame = 3;
      }
      // eslint-disable-next-line no-unused-vars
      const city = this.addr.substring(0, this.addr.length - 1);
      const data = {
        channelCode: window.sessionStorage.getItem("toCode") || "NONE",
        city: city,
        idCard: this.formLabelAlign.inputCard,
        isCarProduction: isCarProduction,
        isProvidentFund: isProvidentFund,
        isRealEstate: isRealEstate,
        isSesame: isSesame,
        loanAmount: price1,
        loanTime: window.sessionStorage.getItem("loanTime"),
        name: this.formLabelAlign.inputName,
        phone: window.sessionStorage.getItem("phone"),
        vocation: this.jobTxt,
        town: this.addr, //市  成都市
        province: this.province, //省份 类四川省
      };
      console.log(data);
      this.isDisabled = true;
      addGolanUser(data).then((response) => {
        console.log(response.data.data);
        console.log(typeof response.data.data);
        if (response.data.code === 200) {
          this.isDisabled = false;
          if (response.data.data.length == 0) {
            this.$router.replace("/Recommend");
          } else {
            window.sessionStorage.setItem(
              "proList",
              JSON.stringify(response.data.data)
            );
            this.$router.replace({ path: "/Wallet" });
          }
        } else {
          this.isDisabled = false;
          this.$toast("服务器异常" + response.data.msg);
        }
      });
    },

    Openpop() {
      this.showPicker = true;
    },
  },
};
</script>

<style scoped>
/deep/ .el-dialog {
  border-radius: 5px;
}

/deep/ .el-button {
  font-size: 16px;
}

/deep/ .el-dialog__footer {
  padding: 5px;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 10px;
}

/deep/ .el-input {
  border: 0;
  /*outline: none;*/
  /*cursor: cell;*/
  /*direction: rtl;*/
  /*unicode-bidi: bidi-override;*/
  text-align: left;
}

/deep/ .el-input__inner {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

/deep/ .el-select {
  display: initial;
}

/deep/ .el-form-item {
  margin-bottom: 0px;
}

/deep/ .el-divider--horizontal {
  margin: 5px 2px;
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #000000;
  font-size: 14px;
}

/deep/ .el-radio__label a {
  color: #2a78fe;
}
</style>
