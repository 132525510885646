<template>
    <div id="app">
        <transition>
            <router-view/>
        </transition>
    </div>
</template>

<script>

// eslint-disable-next-line no-unused-vars


export default {
    name: 'App',
    components: {},
    data() {
        return {}
    },
    created() {
        // TODO vuex 检查登录状态
    },
    methods: {
    },
}
</script>

<style>

@import 'assets/css/Init.css';


#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
}
h4,h2,h1,h3, p {
    margin: 0;
}
button{
    border: 0;
    background:none;
}
input{
    border: 0;
    outline: none;
}
html,
body {
    margin: 0;
    height: 100%;
}
.el-card .el-card__header .action-button {
    float: right;
    padding: 3px 0;
    /*clear: both;*/
}
</style>

