import Vue from 'vue'
import App from './App'
import VueRouter from 'vue-router';
import router from './router';
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n
import 'lib-flexible'
import animated from "animate.css"

Vue.use(VueRouter);
Vue.config.productionTip = false
Vue.use(ElementUI, {locale})
Vue.use(animated);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

