<template>
  <div id="audit">
    <div class="audit_success">
      <div class="audit_bg">
        <div class="audit_content">
          <div class="title">
            <img
              src="https://static.fangxin.plus/h5/old_h5/img/pass.png"
              style="height: 100px; width: 100px"
              alt=""
            />
            <h2>额度审核完成</h2>
            <p>{{ toH5 ? "" : "下载瑞新，查看你的借款额度" }}</p>
          </div>
          <div class="content">
            <p>产品详情</p>
            <div v-for="(item, index) in productList" :key="index">
              <span>{{ item.id }}</span>
              <p>{{ item.desc }}</p>
            </div>
          </div>
          <button
            v-if="hasQueryParam == 'jlqd' || hasQueryParam == 'ppaa'"
            @click="downLoad"
          >
            {{ toH5 ? "已为您匹配到仅1家产品" : "立即下载" }}
          </button>

          <button v-else @click="downLoad">
            {{ toH5 ? "已为您匹配到更多产品" + count : "立即下载" }}
          </button> 
        </div>
      </div>
    </div>
    <div class="server">
      <div class="serve_title">
        <span></span>
        <h2>专业服务 值得信赖</h2>
        <span></span>
      </div>
      <ul>
        <li v-for="(item, index) in serverList" :key="index">
          <div><img :src="item.img" /></div>
          <p>{{ item.p }}</p>
          <span>{{ item.span }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {checkUp, downLoad} from "@/api/api";
import {Toast} from "vant";

export default {
  data() {
    return {
      productList: [
        {id: '专属额度', desc: '￥20000',}, {id: '借款利率', desc: '日息率最低0.02%',}, {
          id: '分期期限',
          desc: '可选3、6、12月',
        }, {id: '还款规则', desc: '当日借款、次日可还、按日计息',}
      ],
      serverList: [
        {img: 'https://static.fangxin.plus/h5/old_h5/img/interest.png', p: '按日计息', span: '可提前还款'},
        {img: 'https://static.fangxin.plus/h5/old_h5/img/quota.png', p: '灵活借款', span: '借款可次日再还'},
        {img: 'https://static.fangxin.plus/h5/old_h5/img/loan.png', p: '超高额度', span: '最高可贷20万'},
      ],
      toH5: false,
      TIME_COUNT: 3,
      count: null,
    }
  },
  computed: {
    hasQueryParam() {
      return   window.sessionStorage.getItem('toCode')
    },
  },
  created() {
    if (window.sessionStorage.getItem("logined") !== "1") {
      this.$router.replace({path: '/'})
      Toast("未登录...")
      return
    }
    this.productList[0].desc = "￥" + window.sessionStorage.getItem("loanAmount") || "20000";
    if (this.productList[0].desc === "") {
      this.productList[0].desc = "￥20000";
    }
    if (window.sessionStorage.getItem("toH5") === "1") {
      this.toH5 = true
    } else {
      this.toH5 = false
    }
    if (this.toH5) {
      this.countTime()
    } else {
      this.checkUp()
    }
  },

  methods: {
    countTime: function () {
      if (!this.timer) {
        this.count = this.TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= this.TIME_COUNT) {
            this.count--
          } else {
            this.count = "";
            this.show = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
            this.$router.replace("/Recommend")
          }
        }, 1000)
      }
    },
    downLoad() {
      if (!this.toH5) {
        downLoad(window.sessionStorage.getItem("toCode")).then(res => {
          window.location.href = res.data
        })
      } else {
        this.$router.replace("/Recommend")
      }
    },

    checkUp() {
      checkUp().then((res) => {
        console.log(res.data.data.url)
        if (res.data.code === 200) {
          this.mineUrl = res.data.data.url

        }
      })
    },
    getCode(){
     let a =  window.sessionStorage.getItem('toCode')
     if(a == "jlqd" || a == "ppaa"){
      this.jlqd_status = false
     }else{
      this.jlqd_status = true
     }
    }
  }
}
</script>
<style lang="scss" scoped>
#audit {
  h2,
  p {
    margin: 0;
  }

  position: relative;
  background: rgba(241, 248, 252, 0.3);
  font-size: 16px;

  .audit_success {
    background-image: url("https://static.fangxin.plus/h5/old_h5/img/rx_icon.jpg");
    background-size: 100%;
    background-repeat: no-repeat;

    .audit_bg {
      padding-top: 130px;
    }

    .audit_content {
      padding: 30px;
      margin: 20px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 10px 30px rgb(196, 211, 246);

      .title {
        color: #2a78fe;

        h2 {
          font-size: 22px;
          font-weight: 400;
          margin: 10px 0 6px 0;
        }
      }
    }

    .content {
      text-align: left;
      margin-top: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 15px;

        span {
          color: #999;
        }

        p {
          text-align: right;
          font-size: 14px;
        }
      }
    }

    button {
      font-size: 16px;
      padding: 15px;
      background: #2a78fe;
      color: #fff;
      border-radius: 25px;
      width: 100%;
      border: 0;
      margin-top: 30px;
    }
  }

  .server {
    text-align: center;
    position: relative;
    padding: 15px 0;

    .serve_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      h2 {
        font-size: 20px;
        font-weight: 400;
        margin: 0 15px;
      }

      span {
        width: 6px;
        height: 6px;
        border: 2px solid #2a78fe;
        transform: rotate(45deg);
      }
    }

    ul {
      display: flex;
      justify-content: space-around;
      padding: 0;

      li {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          box-shadow: 0 0px 10px rgb(196, 211, 246, 0.3);
          background: #fff;
          overflow: hidden;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 40px;
            height: 40px;
          }
        }

        p {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
