<template>
  <div id="Recommend" v-if="Pstatus">
    <div id="head">
      <!-- <img src="https://static.fangxin.plus/h5/old_h5/img/Charts-Gauge.png" alt=""> -->
      <div class="title">
        <p style="color:rgba(166, 166, 166, 1);font-size: .4rem;margin-top: 40px;"> 当前总额度(元)</p>
        <p style="color:rgba(255, 87, 51, 1);font-size: .8rem;margin-top: 10px;">128000</p>
        <p style="color:rgba(166, 166, 166, 1);font-size: .4rem;margin-top: 6px;">额度限时有效，请尽快提现</p>

      </div>
      <div class="apply" @click="getNow">下载提现</div>
    </div>
    <div id="main">
      <div>
        <span class="icon1"><img src="https://static.fangxin.plus/h5/old_h5/img/loader-2-line@2x.png" alt=""
            srcset=""></span>
        &nbsp; <span class="icon-title">匹配成功-待提现</span>&nbsp;
        <span class="icon1"><img src="https://static.fangxin.plus/h5/old_h5/img/loader-2-line@2x.png" alt=""
            srcset=""></span>
      </div>
      <div style="height:100%;padding:0px 0  20px  0">
        <div class="product" v-for="(item, index1) in list" :key="index1">
          <div class="product-box">
            <div style="font-size:.7rem;color:rgba(255, 87, 51, 1);font-weight:bold">&nbsp;¥{{ item.minLoan * 5 }}</div>
            <div style="color:rgba(56, 56, 56, 1);font-size:.3rem;margin-top: 10px;">
              <img class="logo" :src="item.logo" alt="" srcset="">

              <span>&nbsp; 额度由{{ item.name }}提供 </span>

            </div>
          </div>
          <div class="product-box-1">

            <div class="main-btn" @click="getUrl(item.id)">下载提现</div>
          </div>
        </div>


      </div>

    </div>
    <div id="main" class="main2" style="margin-top:50px!important">
      <div>
        <span class="icon1"><img src="https://static.fangxin.plus/h5/old_h5/img/loader-1-line@2x.png" alt=""
            srcset=""></span>
        &nbsp; <span style="color:black;" class="icon-title">匹配失败-未通过</span>&nbsp;
        <span class="icon1"><img src="https://static.fangxin.plus/h5/old_h5/img/loader-1-line@2x.png" alt=""
            srcset=""></span>
      </div>
      <div style="height:100%;padding:0px 0  20px  0">
        <div class="product" style="background-color:rgba(123, 178, 238, 0.13);" v-for="(item, index1) in list1"
          :key="index1">
          <div class="product-box">
            <div style="font-size:.7rem;color:rgba(255, 87, 51, 1);font-weight:bold">&nbsp;¥{{ item.minLoan }}</div>
            <div style="color:rgba(56, 56, 56, 1);font-size:.3rem;margin-top: 10px;">
              <img class="logo" :src="item.logo" alt="" srcset="">

              <span>&nbsp; 额度由{{ item.name }}提供 </span>

            </div>
          </div>
          <div class="product-box-1">

            <div style="background-color: rgba(229, 229, 229, 0.73);color: rgba(128, 128, 128, 1);" class="main-btn">审核失败
            </div>
          </div>
        </div>


      </div>

    </div>
    <div id="foot">
      <div style="font-size:12px;color: rgba(166, 166, 166, 1);vertical-align:middle;">

        <span style="font-size:.4rem;vertical-align: middle;font-weight: bold;">FANGXIN&nbsp;放薪&nbsp;</span><br>
        <img style="width:16px;vertical-align:middle;" src="https://static.fangxin.plus/h5/old_h5/img/icon2.png" alt="">
        <span style="vertical-align: middle;font-size:12px;">&nbsp;保障你的信息安全</span>
      </div>
    </div>
  </div>
</template>


<script>

import { findByIdToShwo, getListApp, getNowApp } from "@/api/api";
import { Toast } from "vant";

export default {
  name: 'recommend',
  data() {

    // return {
    //   nowUrl: "https://information.youkawallet.com/IFLoginFiveTwoHundredFiftySeven?channelId=5178",
    //     list: [{
    //       "name": "放薪",
    //       logo: "http://47.109.35.98:81/h5/sp/image/2022/06-08/1654668664556465232678.jpg",
    //       url: "https://information.youkawallet.com/IFLoginFiveTwoHundredFiftySeven?channelId=5178",
    //       minLoan: 5000,
    //       maxLoan: 50000
    //     },
    //       {
    //         "name": "放薪2",
    //         logo: "http://47.109.35.98:81/h5/sp/image/2022/06-08/1654668664556465232678.jpg",
    //        url: "https://www.baidu.com",
    //         minLoan: 10,
    //         maxLoan: 5000
    //      },
    //       {
    //         "name": "放薪3",
    //         logo: "http://47.109.35.98:81/h5/sp/image/2022/06-08/1654668664556465232678.jpg",
    //         url: "https://www.baidu.com",
    //         minLoan: 10,
    //         maxLoan: 5000
    //       }
    //     ],
    //     list1: [{
    //       "name": "苏宁银行",
    //       logo: require('https://static.fangxin.plus/h5/old_h5/img/snb.png'),
    //       url: "https://information.youkawallet.com/IFLoginFiveTwoHundredFiftySeven?channelId=5178",
    //       minLoan: 20000,
    //       maxLoan: 50000
    //     },
    //       {
    //         "name": "招联好期货",
    //         logo: require('https://static.fangxin.plus/h5/old_h5/img/zlhqd.png'),
    //        url: "https://www.baidu.com",
    //         minLoan: 10000,
    //         maxLoan: 5000
    //      },

    //     ],
    //   }

    return {
      Pstatus: false,
      nowUrl: "",
      list: [],
      list1: [{
        "name": "苏宁银行",
        logo: 'https://static.fangxin.plus/h5/old_h5/img/snb.png',
        url: "https://information.youkawallet.com/IFLoginFiveTwoHundredFiftySeven?channelId=5178",
        minLoan: 20000,
        maxLoan: 50000
      },
      {
        "name": "招联好期货",
        logo: 'https://static.fangxin.plus/h5/old_h5/img/zlhqd.png',
        url: "https://www.baidu.com",
        minLoan: 10000,
        maxLoan: 5000
      },

      ],
    }
  },
  created() {
    if (window.sessionStorage.getItem("logined") !== "1") {
      this.$router.replace({ path: '/' })
      Toast("未登录...")
      return
    }
    this.getList()
  },
  methods: {
    getNow: function () {
      return getNowApp().then((res) => {
        if (res.data.code === 200) {
          this.nowUrl = res.data.data.url;
          findByIdToShwo(res.data.data.id).then((r) => {
            console.log(r)
            this.toNow(res.data.data.url)
          })
        } else {
          this.$toast("未匹配到产品")
        }
      })
    },

    getList: function () {
      return getListApp().then((res) => {
        if (res.data.code === 200) {
          if (res.data.data.length == 0) {
            this.$router.replace({ path: '/ApplyS' })

          } else {
            this.Pstatus = true
            this.list = res.data.data

          }


        } else {
          this.$router.replace({ path: '/ApplyS' })
        }
      })
    },

    getUrl: function (id) {
      findByIdToShwo(id).then((r) => {
        console.log(r.data.data)
        this.toNow(r.data.data)
      })
    },

    toNow: function (url) {
      window.location.href = url
    }
  }
}
</script>


<style lang="scss" scoped>
html {
  font-size: 14px;
}

#Recommend {

  overflow: scroll;
  background: linear-gradient(180deg, rgba(118, 156, 232, 1) 0%, rgba(118, 156, 232, 0.51) 10.31%, rgba(245, 245, 245, 1) 100%);
  // width: 100vw;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}


#head {
  display: flex;

  width: 86%;
  background: #fff;
  height: 240px;
  margin-top: 20px;
  border-radius: 15px;
  padding: 28px 28px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  align-items: center; //垂直居中
  flex-direction: column;
  justify-content: center; //水平居中

  img {
    display: inline-block;
  }

  .title {
    width: 100%;
    height: 100%;
    background: url('https://static.fangxin.plus/h5/old_h5/img/Charts-Gauge.png') no-repeat;
    background-size: 100%;

  }

  .apply {
    height: 44px;
    line-height: 44px;
    width: 90%;
    background-color: rgba(42, 130, 228, 0.8);
    ;
    color: #fff;
    font-size: .4rem;
    margin: 0 auto;
    border-radius: 30px;
    box-sizing: border-box;
    margin-top: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

#main {

  padding: 10px 16px;
  box-sizing: border-box;
  width: 86%;
  height: 100%;
  border-radius: 15px;
  margin-top: 20px;
  background: linear-gradient(180deg, rgba(118, 156, 232, 1) 73.61%, rgba(118, 156, 232, 0) 100%);

  .icon1 img {
    width: .5rem;
    height: .5rem;
    vertical-align: middle;
  }

  .icon-title {
    color: #fff;
    font-size: .5rem;
    vertical-align: middle;
    font-weight: bold;
  }

  .product {
    height: 100%;
    text-align: left;
    box-sizing: border-box;
    padding: 10px 8px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .product-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo {
        width: .7rem;
        height: .7rem;
        vertical-align: middle;
      }

    }

    .main-btn {
      width: 2rem;
      height: 34px;
      background-color: rgba(255, 87, 51, 0.73);
      color: #fff;
      line-height: 34px;
      border-radius: 20px;
      text-align: center;
      font-weight: bold;
      font-size: .34rem;
    }

  }

  .product-box-1 {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.main2 {
  width: 86%;
  background: #fff !important;
  height: 100% !important;
  border-radius: 15px;
}

#foot {
  width: 86%;
  height: 50px;
  margin-top: 20px;
}</style>