<template>
  <div class="bg">
    <div>
      <div :class="{  'head_hidden'  : this.jlqd_status == false}" id="header">
        <div class="title clearfix">
          <img class="logo" src="https://static.fangxin.plus/h5/newH5/img/LOGO.png" alt="">

          <div class="logo_title">
            <p class="font_1">放薪&nbsp;|&nbsp;专业助贷</p>
            <p class="font_2">中国优质科技助贷平台</p>
          </div>

        </div>
      </div>
      <div class="main2">
        <div class="div_1">
          <div class="title_head">
            <img class="rights" src="https://static.fangxin.plus/h5/old_h5/img/rightS.png" alt="">
            &nbsp;初审通过 :总额度3000-20000

          </div>
          <div style="font-weight: bolder;flex: 2;">产品详情</div>
        </div>
        <div class="div_2">

          <div v-for="(item, index) in list" :key="index">
            <span>{{ item.title }}</span>
            <span style="color:rgb(166, 166, 166)">{{ item.msg }}</span>
          </div>


        </div>
      </div>
      <div class="main">
        <!-- <div class="p_1"><img src="https://static.fangxin.plus/h5/newH5/img/right.png" alt=""><span>初审成功</span></div> -->
        <div class="p_2" style="font-size: .4rem;">
          <br>
          请您保持电话畅通，耐心等待助贷经理联系您办理！
        </div>
        <div class="p_3" style="font-size: .4rem;">

          <img class="" src="https://static.fangxin.plus/h5/newH5/img/successs.png" alt=""><span
            style="color:rgba(255, 141, 26, 1);font-size: .5rem;">重要提醒</span><br>
          在收到款项前，无论什么理由都不要向任何人转账支付费用。
        </div>
      </div>

    </div>
    <footer>
      <div v-if="jlqd_status" style="font-size:16px;color: rgba(166, 166, 166, 1.3)">FANGXIN&nbsp;放薪</div>
      <div style="font-size:12px;color: rgba(166, 166, 166,.5);vertical-align:middle;height: 20px;line-height: 20px;">
        <img style="width:20px;vertical-align:middle;" src="https://static.fangxin.plus/h5/newH5/img/icon2.png" alt="">
        保障你的信息安全
        <!-- 客服电话：400-656-8665<br> -->
        <!-- ICP备案号：蜀ICP备2022029731号-2<br> -->
      </div>

    </footer>
  </div>
</template>
<script>

export default {
  data() {
    return {
      product: '',
      jlqd_status:false,
      toH5: false,
      TIME_COUNT: 3,
      count: null,
      list: [
        { title: '借款利率', msg: '日利率最低0.02%' },
        { title: '分期期限', msg: '可选3、6、12、24期' },
        { title: '还款方式', msg: '按日计息,随借随还' },
      ]
    }
  },

  created() {
    this.getCode()
    this.product = JSON.parse(window.sessionStorage.getItem("proList"))[0].product.name;

  },

  methods: {
    countTime: function () {
      if (!this.timer) {
        this.count = this.TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= this.TIME_COUNT) {
            this.count--
          } else {
            this.count = "";
            this.show = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
            this.$router.replace("/Recommend")
          }
        }, 1000)
      }
    },
    getCode(){
     let a =  window.sessionStorage.getItem('toCode')
     if(a == "jlqd" || a == "ppaa"){
      this.jlqd_status = false
     }else{
      this.jlqd_status = true
     }
    }
  }
}
</script>
<style lang="scss" scoped>
#header {
  display: flex;
  justify-content: center;
}

.bg {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  height: 100%;

  h2,
  p {
    margin: 0;
  }

  footer {
    height: 48px;
    width: 100%;
  }



  font-size: 16px;

  position: relative;
  width: 100%;
  background: linear-gradient(180deg, rgba(118, 156, 232, 1) 0%, rgba(118, 156, 232, 0) 70%);
  ;

  .title {
    width: 5.5rem;
    margin: 0 auto;
    display: flex;
    padding-top: 40px;
    justify-content: space-between;

    .logo {
      width: 1.4rem;
      height: 1.4rem;
    }

    .logo_title {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #fff;

      .font_2 {
        font-size: .3rem;
        text-align: left;
      }

      .font_1 {
        font-size: .4rem;
        text-align: left;
      }
    }
  }

  .main {
    padding:2px 6px;
    box-sizing: border-box;
    background-color: #fff;
    width: 8rem;
    height: 200px;
    margin: 0 auto;
    margin-top: .8rem;
    border-radius: .2rem;
    padding-top: 10px;

    .p_1 {
      img {
        width: .7rem;
        height: .7rem;
        vertical-align: middle;
      }

      span {
        font-size: .5rem;
        vertical-align: middle;
        margin-left: 10px;
        font-weight: bolder;
      }
    }

    .p_2 {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      padding: 10px;
    }

    .p_3 {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      padding: 10px;
      font-size: .4rem;

      img {
        vertical-align: middle;
        width: .7rem;
      }

      ;
    }

  }

  .color2 {

    color: #2A82E4;
  }

  .font-1 {
    font-size: .4rem;
  }

  .clearfix:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .clearfix {
    *zoom: 1;
    /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
  }
}

.main2 {
  display: flex;
  flex-direction: column;
  padding: 10px;
  
  box-sizing: border-box;
  background-color: #fff;
  width: 8rem;
  height: 270px;
  margin: 0 auto;
  margin-top: .8rem;
  border-radius: .2rem;
  padding-top: 20px;

  .div_1 {
    display: flex;
    flex-direction: column;
    height: 40%;
    border-bottom: 1px solid #ccc ;
    padding: 0px 6px ;
    .title_head{
      background-color:rgba(214, 223, 241, 1);
      color: rgba(255, 87, 51, 1);
      border-radius: .2rem;
      font-size: .42rem;
      height: 40%;
    }
  
    div{
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      .rights{
      height: .6rem;
      width: .6rem;
      vertical-align: middle;
    }
    }
  }

  .div_2 {
    height: 60%;
    display: flex;
    flex-direction: column;
    padding: 10px 6px;
    font-size: 0.4rem;
    div {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

}
.head_hidden{
    visibility: hidden;
  }
</style>
