import request from "@/utils/request";

export function getDemo() {
    return request({
        url: '/test/test1?id=' + 1,
        method: "get",
    })
}

export function sendVerifyCode(data) {
    console.log(data)
    return request({
        url: '/app/sendVerifyCode',
        method: "post",
        data: {
            "code": "1",
            "mobile": data.mobile,
            "sign": "",
            "type": 0
        }
    })
}

export function sendVerifyCodeAndLogin(data) {
    console.log(data)
    if (data.code === null || data.code === undefined || data.code === "") {
        data.code = "123456"
    }
    return request({
        url: '/app/sendVerifyCodeAndLogin',
        method: "post",
        data: {
            "code": "" + data.code,
            "mobile": "" + data.mobile,
            "verifyCode": "" + data.verifyCode,
            "type": 0
        }
    })
}



export function addGolanUser(data,flag) {
    if (flag == 1) {
        return request({
            url: '/UserAssets/addGolanUserNew',
            method: "post",
            data: {
                "age": data.age,
                "birthday": data.birthday,
                "channelCode": data.channelCode,
                "city": data.city,
                "education": data.education,
                "idCard": data.idCard,
                "isCarProduction": data.isCarProduction,
                "isProvidentFund": data.isProvidentFund,
                "isRealEstate": data.isRealEstate,
                "isSesame": data.isSesame,
                "isSuc": data.isSuc,
                "loanAmount": data.loanAmount,
                "loanTime": data.loanTime,
                "name": data.name,
                "phone": data.phone,
                "pushType": data.pushType,
                "salary": data.salary,
                "vocation": data.vocation
            }
        })
    }else{
        return request({
            url: '/UserAssets/addGolanUser',
            method: "post",
            data: {
                "age": data.age,
                "birthday": data.birthday,
                "channelCode": data.channelCode,
                "city": data.city,
                "education": data.education,
                "idCard": data.idCard,
                "isCarProduction": data.isCarProduction,
                "isProvidentFund": data.isProvidentFund,
                "isRealEstate": data.isRealEstate,
                "isSesame": data.isSesame,
                "isSuc": data.isSuc,
                "loanAmount": data.loanAmount,
                "loanTime": data.loanTime,
                "name": data.name,
                "phone": data.phone,
                "pushType": data.pushType,
                "salary": data.salary,
                "vocation": data.vocation
            }
        })
    }
   
}

export function checkUp() {
    window.sessionStorage.getItem("toCode");
    return request({
        url: '/Other/checkUp',
        method: "get",
        params: window.sessionStorage.getItem("toCode")
    })
}

export function getNowApp() {
    return request({
        url: '/Product/applyNow',
        method: "get",
    })
}


export function getListApp() {
    return request({
        url: '/Product/getList',
        method: "get",
    })
}

export function login(data) {
    return request({
        url: '/app/login',
        method: "post",
        data: {
            code: data.code || "123456",
            mobile: data.mobile,
            verifyCode: data.verifyCode
        }
    })
}


export function findByIdToShwo(id) {
    return request({
        url: '/Product/findByIdToShwo',
        method: "get",
        params: {
            id: id
        }
    })
}

export function idCarValid(data) {
    return request({
        url: '/UserAssets/idCarValidNew',
        method: "post",
        data: data
    })
}

export function downLoad(code) {
    return request({
        url: '/Other/download?code=' + code,
        method: "get",
    })
}

export function pushUserAssets(data) {
    return request({
        url: '/UserAssets/PushUserAssets',
        method: "post",
        data: data
    })
}
export function PushUserUrl(data) {
    return request(
        {
            url: '/UserAssets/PushUserUrl',
            method: "post",
            data: data
        })
}


export function getChannelUrl(code) {
    return request({
                url: `/app/getChannelUrl?code=${code}&test=2`,
            method: "get",
        })
    }

    export function UserChoose() {
        return request({
            url: `/UserAssets/golanUserChoose`,
            method: "get",
        })
    }